// al final de tot hi ha un export
function llista_rutes() {
	var rutes = [
		{"zona":"TNR","id":"tnr","rutes":[
			{"ruta":"TNR-7", "fitxer":"TNR-7.gpx", "desc":"BTT*TNR*TNR-7.gpx*2024-07-09*84.9 Km*07:29:37*10:28:29*4751 m*3378 m*769m*2242m*"},
			{"ruta":"TNR-1", "fitxer":"TNR-1.gpx", "desc":"BTT*TNR*TNR-1.gpx*2024-07-03*57.8.0 Km*03:50:01*05:08:31*1991 m*1717 m*224m*1182m*"},
			{"ruta":"TNR-4", "fitxer":"TNR-4.gpx", "desc":"BTT*TNR*TNR-4.gpx*2024-07-06*50.2 Km*04:19:35*07:43:03*2569 m*2316 m*1181m*2362m*"},
			{"ruta":"TNR-8", "fitxer":"TNR-8.gpx", "desc":"BTT*TNR*TNR-8.gpx*2024-07-10*77.4 Km*05:45:43*09:20:22*4945 m*3536 m*298m*2032m*"},
			{"ruta":"TNR-9", "fitxer":"TNR-9.gpx", "desc":"BTT*TNR*TNR-9.gpx*2024-07-11*66.6 Km*04:13:30*06:17:05*3080 m*2206 m*3m*1101m*"},
			{"ruta":"TNR-5", "fitxer":"TNR-5.gpx", "desc":"BTT*TNR*TNR-5.gpx*2024-07-07*103.6 Km*07:40:11*10:48:43*4903 m*3592 m*736m*2739m*"},
			{"ruta":"TNR-6", "fitxer":"TNR-6.gpx", "desc":"BTT*TNR*TNR-6.gpx*2024-07-08*93.3 Km*05:22:45*10:24:05*3604 m*2630 m*596m*2533m*"},
			{"ruta":"TNR-3", "fitxer":"TNR-3.gpx", "desc":"BTT*TNR*TNR-3.gpx*2024-07-05*75.9 Km*05:35:29*08:07:11*3405 m*2730 m*1181m*2537m*"},
			{"ruta":"TNR-0", "fitxer":"TNR-0.gpx", "desc":"BTT*TNR*TNR-0.gpx*2024-07-03*18.3 Km*01:20:12*01:27:02*309 m*277 m*214m*299m*"},
			{"ruta":"TNR-2", "fitxer":"TNR-2.gpx", "desc":"BTT*TNR*TNR-2.gpx*2024-07-04*74.4 Km*07:08:32*10:38:50*3943 m*3299 m*367m*2103m*"}
		]},
		{"zona":"Vía de la Plata","id":"via_de_la_plata","rutes":[
			{"ruta":"4VP-Cáceres-Galisteo", "fitxer":"4VP-caceres-galisteo.gpx", "desc":"BTT*Vía de la Plata*4VP-caceres-galisteo.gpx*2023-07-04*73.4 Km*04:19:04*06:08:28*1011 m*870 m*202m*495m*"},
			{"ruta":"7VP-Salamanca-Zamora-Granja de Moreruela", "fitxer":"7VP-salamanca-zamora-granja_de_moreruela.gpx", "desc":"BTT*Vía de la Plata*7VP-salamanca-zamora-granja_de_moreruela.gpx*2023-07-07*111.8 Km*05:04:27*09:14:41*1059 m*877 m*621m*884m*"},
			{"ruta":"10VP-Vilar de Barrio-Ourense-Silleda", "fitxer":"10VP-vilar_de_barrio-ourense-silleda.gpx", "desc":"BTT*Vía de la Plata*10VP-vilar_de_barrio-ourense-silleda.gpx*2023-07-10*111.0 Km*07:13:43*12:28:14*2487 m*2268 m*101m*819m*"},
			{"ruta":"3VP-Villafranca de los Barros-Mérida-Cáceres", "fitxer":"3VP-villafranca_de_los_barros-merida-caceres.gpx", "desc":"BTT*Vía de la Plata*3VP-villafranca_de_los_barros-merida-caceres.gpx*2023-07-03*119.3 Km*05:37:29*10:58:26*1151 m*970 m*201m*489m*"},
			{"ruta":"1VP-Sevilla-Castilblanco de los Arroyos", "fitxer":"1VP-sevilla-castilblanco_de_los_arroyos.gpx", "desc":"BTT*Vía de la Plata*1VP-sevilla-castilblanco_de_los_arroyos.gpx*2023-07-01*46.0 Km*02:52:32*04:41:20*748 m*675 m*0m*351m*"},
			{"ruta":"8VP-Granja de Moreruela-Puebla de Sanabria", "fitxer":"8VP-granja_de_moreruela-puebla_de_sanabria.gpx", "desc":"BTT*Vía de la Plata*8VP-granja_de_moreruela-puebla_de_sanabria.gpx*2023-07-08*114.8 Km*06:32:08*09:49:36*1468 m*1312 m*684m*1013m*"},
			{"ruta":"11VP-Silleda-Santiago de Compostela", "fitxer":"11VP-silleda-santiago_de_compostela.gpx", "desc":"BTT*Vía de la Plata*11VP-silleda-santiago_de_compostela.gpx*2023-07-11*41.1 Km*02:34:28*02:57:07*998 m*910 m*54m*494m*"},
			{"ruta":"6VP-La Calzada de Béjar-Salamanca", "fitxer":"6VP-la_calzada_de_bejar-salamanca.gpx", "desc":"BTT*Vía de la Plata*6VP-la_calzada_de_bejar-salamanca.gpx*2023-07-06*78.0 Km*04:23:04*06:35:45*1074 m*989 m*771m*1098m*"},
			{"ruta":"9VP-Puebla de Sanabria-Vilar de Barrio", "fitxer":"9VP-puebla_de_sanabria-vilar_de_barrio.gpx", "desc":"BTT*Vía de la Plata*9VP-puebla_de_sanabria-vilar_de_barrio.gpx*2023-07-09*114.6 Km*06:53:39*09:09:00*3127 m*2664 m*466m*1356m*"},
			{"ruta":"5VP-Galisteo-La Calzada de Béjar", "fitxer":"5VP-galisteo-la_calzada_de_bejar.gpx", "desc":"BTT*Vía de la Plata*5VP-galisteo-la_calzada_de_bejar.gpx*2023-07-05*73.2 Km*04:25:38*06:18:29*1294 m*1146 m*245m*897m*"},
			{"ruta":"2VP-Castilblanco de los Arroyos-Villafranca de los Barros", "fitxer":"2VP-castilblanco_de_los_arroyos-villafranca_de_los_barros.gpx", "desc":"BTT*Vía de la Plata*2VP-castilblanco_de_los_arroyos-villafranca_de_los_barros.gpx*2023-07-02*129.3 Km*07:38:59*13:23:12*2094 m*1924 m*299m*756m*"}
		]},
		{"zona":"Berguedà","id":"bergueda","rutes":[
			{"ruta":"Malanyeu-Grau del Griell-Foradada", "fitxer":"malanyeu-grau_del_griell-foradada.gpx", "desc":"senderisme*Berguedà*malanyeu-grau_del_griell-foradada.gpx*2021-07-11*4.0 Km*01:21:21*02:01:46*538 m*426 m*1010m*1321m*"},
			{"ruta":"Riutort - Pobla de Lillet", "fitxer":"riutort_pobla_de_lillet.gpx", "desc":"BTT*Berguedà*riutort_pobla_de_lillet.gpx*2020-01-11*14.4 Km*00:04:34 *00:04:34*734 m*652 m*781m*1417m*vmqX1GGWYov"},
			{"ruta":"Les Rovires de Baix 6", "fitxer":"les_rovires_de_baix_6.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_6.gpx*2022-06-23*7.8 Km*00:46:52*00:46:52*354 m*294 m*687m*876m*"},
			{"ruta":"Les Rovires de Baix-Tordelespà", "fitxer":"les_rovires_de_baix-tordelespa.gpx", "desc":"senderisme*Berguedà*les_rovires_de_baix-tordelespa.gpx*2022-10-31*12.6 Km*02:19:04*06:03:32*515 m*523 m*723m*875m*"},
			{"ruta":"Berga-Cal Rosal St Maurici de la Quar-La Portella", "fitxer":"berga-cal_rosal_st_maurici_de_la_quar-la_portella.gpx", "desc":"BTT*Berguedà*berga-cal_rosal_st_maurici_de_la_quar-la_portella.gpx*2022-04-11*46.7 Km*04:25:39*05:05:44*1761 m*1242 m*469m*1049m*"},
			{"ruta":"Carretera del Jou", "fitxer":"carretera_del_jou.gpx", "desc":"BTT*Berguedà*carretera_del_jou.gpx*2021-08-23*11.1 Km*01:28:24*02:00:12*332 m*283 m*881m*1043m*"},
			{"ruta":"ToDo-Sant Jaume de Frontanyà - Gombrèn v2", "fitxer":"todo-gombren-sant-jaume-de-frontanya-gombren_v2.gpx", "desc":"BTT*Berguedà*todo-gombren-sant-jaume-de-frontanya-gombren_v2.gpx*2020-12-18*45.5 Km*01:30:30 *01:30:30*1612 m*981 m*777m*1385m*"},
			{"ruta":"ToDo-Volta a la Serra d'Ensija", "fitxer":"todo-volta_serra_ensija.gpx", "desc":"BTT*Berguedà*todo-volta_serra_ensija.gpx*2021-01-12*27.8 Km*00:43:00*00:43:00*1572 m*1032 m*1144m*1830m*"},
			{"ruta":"ToDo-Cercs-Figuerassa", "fitxer":"todo-cercs-figuerassa.gpx", "desc":"BTT*Berguedà*todo-cercs-figuerassa.gpx*2022-10-22*25.9 Km*00:20:08*00:54:08*1229 m*809 m*691m*1492m*"},
			{"ruta":"El Collet-Coll de Pradell", "fitxer":"collet-coll_pradell.gpx", "desc":"ciclisme*Berguedà*collet-coll_pradell.gpx*2024-03-23*15.4 Km*01:36:30*01:40:18*1341 m*1267 m*710m*1745m*"},
			{"ruta":"ToDo-Campdevànol-Les Rovires", "fitxer":"todo-campdevanol-les_rovires.gpx", "desc":"BTT*Berguedà*todo-camprodon-les_rovires.gpx*2021-08-02*1.0 Km*01:00:00*01:00:00*100 m*100 m*100m*100m*"},
			{"ruta":"Pedró de Tubau", "fitxer":"pedro_de_tubau.gpx", "desc":"senderisme*Berguedà*pedro_de_tubau.gpx*2023-10-12*13.6 Km*20:10:14*21:19:15*773 m*684 m*1114m*1529m*"},
			{"ruta":"Vallcebre-Coll de Pradell-Cingles de Costafreda", "fitxer":"vallcebre-coll_de_pradell-cingles_de_costafreda.gpx", "desc":"BTT*Berguedà*vallcebre-coll_de_pradell-cingles_de_costafreda.gpx*2022-08-03*15.8 Km*01:18:32*01:26:27*795 m*722 m*1123m*1745m*"},
			{"ruta":"Mirador de la Figuerassa", "fitxer":"mirador_de_la_figuerassa.gpx", "desc":"senderisme*Berguedà*mirador_de_la_figuerassa.gpx*2023-02-20*5.8 Km*01:40:40*01:49:24*336 m*279 m*1312m*1493m*"},
			{"ruta":"ToDo-Sant Jaume de Frontanyà - Gombrèn v1", "fitxer":"todo-gombren-sant-jaume-de-frontanya-gombren_v1.gpx", "desc":"BTT*Berguedà*todo-gombren-sant-jaume-de-frontanya-gombren_v1.gpx*2020-12-18*45.5 Km*01:30:30 *01:30:30*1813 m*1094 m*777m*1377m*"},
			{"ruta":"ToDo-Volta als Rasos de Tubau", "fitxer":"todo-volta_rasos_tubau.gpx", "desc":"BTT*Berguedà*todo-todo-volta_rasos_tubau.gpx*2021-01-12*45.1 Km*01:23:00*01:23:00*1699 m*915 m*723m*1219m*"},
			{"ruta":"La Sala-Sta Cecília de Riutort-Coll Cerqueda-Coll Portelletes", "fitxer":"la_sala-sta_cecilia_riutort-coll_cerqueda-coll_portelletes.gpx", "desc":"BTT*Berguedà*la_sala-sta_cecilia_riutort-coll_cerqueda-coll_portelletes.gpx*2020-07-02*17.2 Km*01:21:59 *02:10:15*809 m*668 m*793m*1299m*vZqNNRxKxGq"},
			{"ruta":"ToDo-Rovires de Baix-Pedró de Tubau-v2", "fitxer":"todo-rovires_de_baix-pedro_tubau-v2.gpx", "desc":"BTT*Berguedà*todo-rovires_de_baix-pedro_tubau-v2.gpx*2022-10-22*46.1 Km*00:20:08*00:54:08*1888 m*1121 m*743m*1529m*"},
			{"ruta":"ToDo-Coll de Pal-Pobla de Lillet", "fitxer":"todo-coll_de_pal-pobla_de_lillet.gpx", "desc":"BTT*Berguedà*todo-coll_de_pal-pobla_de_lillet.gpx*2021-01-12*15.8 Km*00:22:00*00:22:00*357 m*65 m*854m*1967m*"},
			{"ruta":"Les Rovires de Baix 1", "fitxer":"les_rovires_de_baix_1.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_1.gpx*2020-08-08*15.5 Km*02:55:10*02:55:10*710 m*536 m*732m*952m*vRO7dZ1pYy6"},
			{"ruta":"Cercs - Peguera - Cercs", "fitxer":"cercs-peguera.gpx", "desc":"BTT*Berguedà*cercs-peguera.gpx*2013-07-13*37.9 Km*00:10:16 *06:24:24*1715 m*1423 m*635m*1830m*vdvmB4no4NO"},
			{"ruta":"ToDo-Ripoll-Les Rovires", "fitxer":"todo-ripoll-les_rovires.gpx", "desc":"BTT*Berguedà*todo-ripoll-les_rovires.gpx*2021-08-02*1.0 Km*01:00:00*01:00:00*100 m*100 m*100m*100m*"},
			{"ruta":"La Catllaràs 2018", "fitxer":"la-catllaras-2018.gpx", "desc":"BTT*Berguedà*la-catllaras-2018.gpx*2018-10-22*47.8 Km*00:22:42 *03:44:41*2213 m*1865 m*871m*1695m*vXOd3mjA54v"},
			{"ruta":"La Pobla de Lillet-Gombrèn-Castellar de n'Hug", "fitxer":"la_pobla_de_lillet-gombren-castellar_de_nhug.gpx", "desc":"BTT*Berguedà*la_pobla_de_lillet-gombren-castellar_de_nhug.gpx*2021-11-06*45.8 Km*03:48:20*04:14:34*1755 m*1362 m*836m*1415m"},
			{"ruta":"Montclar-Montmajor-Cardona", "fitxer":"montclar-montmajor-cardona.gpx", "desc":"BTT*Berguedà*montclar-montmajor-cardona.gpx*2021-08-29*50.8 Km*03:13:43*03:34:43*1355 m*1063 m*403m*820m*"},
			{"ruta":"Les Rovires de Baix-Ripoll", "fitxer":"les_rovires_de_baix-ripoll.gpx", "desc":"ciclisme*Berguedà*les_rovires_de_baix-ripoll.gpx*2023-04-08*25.9 Km*01:09:36*01:14:20*686 m*423 m*666m*982m*"},
			{"ruta":"Queralt-Espinalbet-La Figuerassa", "fitxer":"queralt-espinalbet-la_figuerassa.gpx", "desc":"BTT*Berguedà*queralt-espinalbet-la_figuerassa.gpx*2023-08-19*17.5 Km*02:33:01*03:34:40*1048 m*818 m*920m*1493m*"},
			{"ruta":"ToDo-Vilada-La Nou-Malanyeu-La Clusa", "fitxer":"todo-vilada-la_nou-malanyeu-la_clusa.gpx", "desc":"BTT*Berguedà*todo-vilada-la_nou-malanyeu-la_clusa.gpx*2022-10-22*34.3 Km*00:20:08*00:54:08*1909 m*1168 m*643m*1586m*"},
			{"ruta":"ToDo-El Collet-Vallcebre-Coll de Pradell-Saldes-Riera Saldes", "fitxer":"todo-el_collet-vallcebre-coll_de_pradell-saldes-riera_saldes.gpx", "desc":"BTT*Berguedà*todo-el_collet-vallcebre-coll_de_pradell-saldes-riera_saldes.gpx*2021-01-12*40.1 Km*00:57:00*00:57:00*1972 m*1150 m*718m*1738m*"},
			{"ruta":"Cavalls del Vent", "fitxer":"cavalls_del_vent.gpx", "desc":"senderisme*Berguedà*cavalls_del_vent.gpx*2004-08-15*78.9 Km*00:00:40*05:51:00*6709 m*5459 m*910m*2520m*"},
			{"ruta":"Les Rovires de Baix 4", "fitxer":"les_rovires_de_baix_4.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_4.gpx*2021-10-10*25.4 Km*02:12:00*02:15:44*1067 m*831 m*731m*1099m*"},
			{"ruta":"Baix Berguedà", "fitxer":"baix_bergueda.gpx", "desc":"ciclisme*Berguedà*baix_bergueda.gpx*2023-08-20*68.2 Km*03:31:26*04:43:18*1584 m*1175 m*351m*891m*"},
			{"ruta":"Les Rovires de Baix 7", "fitxer":"les_rovires_de_baix_7.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_7.gpx*2022-10-16*32.6 Km*02:37:24*02:38:48*1490 m*1029 m*742m*1262m*"},
			{"ruta":"La Nou-La Baells-Berga-Cercs", "fitxer":"la_nou-la_baells-berga-cercs.gpx", "desc":"ciclisme*Berguedà*la_nou-la_baells-berga-cercs.gpx*2023-08-16*28.3 Km*02:20:00*02:20:00*1116 m*623 m*624m*927m*"},
			{"ruta":"Les Rovires-Borredà a peu", "fitxer":"les_rovires-borreda-a_peu.gpx", "desc":"senderisme*Berguedà*les_rovires-borreda-a_peu.gpx*2021-04-03*4.0 Km*01:56:04*02:13:32*236 m*193 m*769 m*922 m*"},
			{"ruta":"berga-cal_rosal-montsent_(el_banc)", "fitxer":"berga-cal_rosal-montsent_(el_banc).gpx", "desc":"BTT*Berguedà*berga-cal_rosal-montsent_(el_banc).gpx*2022-08-08*23.8 Km*02:32:32*03:11:19*1166 m*966 m*483m*934m*"},
			{"ruta":"ToDo-Bagà-Gréixer-Escriu-Pendís-Bellver", "fitxer":"todo-baga-greixer-escriu-pendis-bellver.gpx", "desc":"BTT*Berguedà*todo-baga-greixer-escriu-pendis-bellver.gpx*2021-01-12*24.1 Km*00:28:00*00:28:00*950 m*864 m*815m*1815m*"},
			{"ruta":"ToDo-Casserres-Montclar", "fitxer":"todo-Casserres-Montclar.gpx", "desc":"BTT*Berguedà*todo-Casserres-Montclar.gpx*2020-12-18*23.8 Km*00:10:06*00:10:06*591 m*542 m*554m*773m*"},
			{"ruta":"ToDo-Baix Berguedà", "fitxer":"todo-baix_bergueda.gpx", "desc":"ciclisme*Berguedà*todo-baix_bergueda.gpx*2021-01-04*67.0 Km*00:44:40*00:45:00*1274 m*829 m*351m*888*"},
			{"ruta":"La Pobla de Lillet-Falgars", "fitxer":"la_pobla_de_lillet-falgars.gpx", "desc":"senderisme*Berguedà*la_pobla_de_lillet-falgars.gpx*2022-12-30*3.9 Km*00:53:40*01:23:52*500 m*448 m*849m*1274m*"},
			{"ruta":"Bagà-Sant Julià de Cerdanyola-Malanyeu-La Nou-Vilada-Sant Quirze de Besora", "fitxer":"baga-st_julia_cerdanyola-malanyeu-la_nou-vilada-st_quirze_besora.gpx", "desc":"BTT*Berguedà*baga-st_julia_cerdanyola-malanyeu-la_nou-vilada-st_quirze_besora.gpx*2022-12-18*69.0 Km*04:50:12*05:24:06*2498 m*1843 m*568m*1385m*"},
			{"ruta":"Les Rovires de Baix 3", "fitxer":"les_rovires_de_baix_3.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_3.gpx*2020-08-08*15.5 Km*02:56:00 *02:56:00*710 m*536 m*732m*952m*veqzDYKQj7O"},
			{"ruta":"Les Rovires de Baix 2", "fitxer":"les_rovires_de_baix_2.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_2.gpx*2020-03-08*15.4 Km*01:14:39 *01:36:36*764 m*606 m*674m*972m*vevW7ZW3pyv"},
			{"ruta":"Ripoll-Les Rovires de Baix", "fitxer":"ripoll-les_rovires_de_baix.gpx", "desc":"BTT*Berguedà*ripoll-les_rovires_de_baix.gpx*2020-06-26*26.0 Km*01:30:56 *01:49:12*783 m*541 m*665m*977m*vKv2R72o14v"},
			{"ruta":"Serra d'Ensija-Gallina Pelada", "fitxer":"serra_ensija-gallina_pelada.gpx", "desc":"senderisme*Berguedà*serra_ensija-gallina_pelada.gpx*2024-03-30*9.7.0 Km*03:27:49*05:59:44*735 m*657 m*1669m*2300m*"},
			{"ruta":"Les Rovires de Baix 5", "fitxer":"les_rovires_de_baix_5.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix_5.gpx*2022-03-06*37.8 Km*02:59:32*03:03:52*1488 m*1055 m*743m*1135m*"},
			{"ruta":"Pedraforca", "fitxer":"pedraforca.gpx", "desc":"senderisme*Berguedà*pedraforca.gpx*2021-12-16*49.0 Km*03:20:08*03:54:08*1571 m*1107 m*162m*617m*"},
			{"ruta":"ToDo-Campdevànol-Gombrèn-Pedro de Tubau-Riera de Merlès-Les Rovires", "fitxer":"todo-campdevanol-gombren-pedro_tubau-riera_merles-les_rovires.gpx", "desc":"BTT*Berguedà*todo-campdevanol-gombren-pedro_tubau-riera_merles-les_rovires.gpx*2022-10-22*44.2 Km*00:20:08*00:54:08*1848 m*1111 m*740m*1524m*"},
			{"ruta":"Plantiu-Coll de Cerqueda-Erols-Sant Vicenç de Rus-La Pobla", "fitxer":"el_plantiu-coll_cerqueda-erols-sant_vicenc_de_rus.gpx", "desc":"BTT*Berguedà*el_plantiu-coll_cerqueda-erols-sant_vicenc_de_rus.gpx*2021-08-24*20.8 Km*01:43:52*01:43:52*881 m*703 m*815m*1472m*"},
			{"ruta":"ToDo-Bagà-Gisclareny-Coll de Bauma-El Collell-Riera de Saldes", "fitxer":"todo-baga-gisclareny-coll_bauma-el_collell-riera_saldes.gpx", "desc":"BTT*Berguedà*todo-baga-gisclareny-coll_bauma-el_collell-riera_saldes.gpx*2021-01-12*46.1 Km*01:12:00*01:12:00*2511 m*1240 m*789m*1924m*"},
			{"ruta":"Cercs-L'Erola", "fitxer":"cercs-l_erola.gpx", "desc":"BTT*Berguedà*cercs-l_erola.gpx*2023-06-02*12.7 Km*01:25:52*01:37:04*925 m*815 m*646m*1210m*"},
			{"ruta":"ToDo-Rovires de Baix-Pedró de Tubau-v1", "fitxer":"todo-rovires_de_baix-pedro_tubau-v1.gpx", "desc":"BTT*Berguedà*todo-rovires_de_baix-pedro_tubau-v1.gpx*2022-10-22*65.3 Km*0:20:08*00:54:08*2409 m*1454 m*684m*1529m*"},
			{"ruta":"Sant Quirze de Besora-Riera de Merlès", "fitxer":"st_quirze_besora-riera_merles.gpx", "desc":"BTT*Berguedà*st_quirze_besora-riera_merles.gpx*2021-06-26*27.6 Km*02:47:45*03:39:20*1018 m*779 m*565m*890m*"},
			{"ruta":"Les Rovires de Baix-Pedró de Tubau", "fitxer":"les_rovires_de_baix-pedro_de_tubau.gpx", "desc":"BTT*Berguedà*les_rovires_de_baix-pedro_de_tubau.gpx*2022-10-30*46.0 Km*03:38:17*03:48:49*1867 m*1404 m*742m*1529m*"},
			{"ruta":"Malanyeu-La Nou de Berguedà", "fitxer":"malanyeu-la_nou_de_bergueda.gpx", "desc":"ciclisme*Berguedà*malanyeu-la_nou_de_bergueda.gpx*2022-08-04*16.7 Km*01:17:32*01:20:36*888 m*738 m*687m*1124m*"}
		]},
		{"zona":"Sant Feliu","id":"sant_feliu","rutes":[
			{"ruta":"Sant Feliu-Sant Miquel del Fai-Sant Quirze Safaja", "fitxer":"sant_feliu-sant_miquel_del_fai-sant_quirze_safaja.gpx", "desc":"ciclisme*Sant Feliu*sant_feliu-sant_miquel_del_fai-sant_quirze_safaja.gpx*2023-07-18*22.0 Km*00:58:23*00:58:23*978 m*802 m*455m*694m*"},
			{"ruta":"Sant Feliu de Codines-Sabadell v2", "fitxer":"sant_feliu_de_c-sabadell_v2.gpx", "desc":"BTT*Sant Feliu*sant_feliu_de_c-sabadell.gpx*2021-05-30*28.6 Km*02:16:56*02:19:44*791 m*583 m*138m*621m*"},
			{"ruta":"St Feliu de Codines-Tenes-Besós-BCN", "fitxer":"st_feliu_codines-tenes-besos-bcn.gpx", "desc":"BTT*Sant Feliu*st_feliu_codines-tenes-besos-bcn.gpx*2020-09-20*55.5 Km*02:51:40 *03:05:16*595 m*270 m*11m*537m*vNOPPMJmWYO"},
			{"ruta":"Sant Feliu de C-Vacarisses", "fitxer":"sant_feliu_de_c-vacarisses.gpx", "desc":"BTT*Sant Feliu*sant_feliu_de_c-vacarisses.gpx*2021-07-04*44.4 Km*03:10:43*03:23:43*1470 m*1182 m*314m*761m*"},
			{"ruta":"St Feliu-Volta a la Popa", "fitxer":"st_feliu_cod-volta_a_la_popa.gpx", "desc":"BTT*Sant Feliu*st_feliu_cod-volta_a_la_popa.gpx*2021-03-31*35.0 Km*03:27:20*03:47:36*1113 m*906 m*472 m*887 m*"},
			{"ruta":"Sant Feliu de Codines-Sabadell", "fitxer":"sant_feliu_codines_sabadell.gpx", "desc":"BTT*Sant Feliu*sant_feliu_codines_sabadell.gpx*2019-11-21*33.4 Km*02:03:28 *02:14:43*801 m*619 m*116m*621m*vNOPnEm5K2q"},
			{"ruta":"St Feliu-Unyó-Ullà", "fitxer":"st_miquel_del_fai_uno_ulla.gpx", "desc":"senderisme*Sant Feliu*st_miquel_del_fai_uno_ulla.gpx*2015-03-30*6.4 Km*00:00:46 *03:00:27*604 m*309 m*467m*778m*vMq5dwywkQ6"},
			{"ruta":"Volta per Sant Feliu 5", "fitxer":"volta_per_sant_feliu_5.gpx", "desc":"BTT*Sant Feliu*volta_per_sant_feliu_5.gpx*2022-04-09*7.8 Km*00:43:36*00:46:00*275 m*230 m*472m*640m*"},
			{"ruta":"St Feliu de Codines-Gallifa-El Farell-Caldes", "fitxer":"st_feliu_codines_gallifa_farell_caldes.gpx", "desc":"BTT*Sant Feliu*st_feliu_codines_gallifa_farell_caldes.gpx*2020-06-13*29.6 Km*01:46:34*02:30:04*979 m*797 m*230m*691m*vMq5d2w37Q6"},
			{"ruta":"Moià-Estany-Rodons-Montbrú", "fitxer":"moia-estany-rodons-montbru.gpx", "desc":"BTT*Sant Feliu*moia-estany-rodons-montbru.gpx*2022-04-12*37.9 Km*02:35:08*03:11:48*1415 m*1056 m*541m*1032m*"},
			{"ruta":"Sant Feliu-Gorg Negre", "fitxer":"sant_feliu-gorg_negre.gpx", "desc":"BTT*Sant Feliu*sant_feliu-gorg_negre.gpx*2022-08-28*10.8 Km*01:08:20*01:17:44*503 m*365 m*356m*533m*"},
			{"ruta":"Centelles - Sant Feliu", "fitxer":"centelles_stfeliu.gpx", "desc":"BTT*Sant Feliu*centelles_stfeliu.gpx*2019-11-16*29.5 Km*02:14:00 *02:26:19*965 m*777 m*464m*877m*vNOPnEm5V2q"},
			{"ruta":"Caldes de Montbui-Castell de Montbui-St Feliu-St Quirze", "fitxer":"caldes_montbui-castell_montbui-st_feliu-st_quirze.gpx", "desc":"BTT*Sant Feliu*caldes_montbui-castell_montbui-st_feliu-st_quirze.gpx*2023-09-03*26.4 Km*02:10:59*03:05:27*1031 m*924 m*235m*709m*"},
			{"ruta":"Sant Feliu de Codines-Les Rovires de Baix", "fitxer":"st_feliu_de_codines-les_rovires_de_baix.gpx", "desc":"ciclisme*Sant Feliu*st_feliu_de_codines-les_rovires_de_baix.gpx*2023-04-07*74.1 Km*03:44:20*04:17:08*2071 m*1669 m*453m*888m*"},
			{"ruta":"Sant Vicenç de Castellet-Obac-Estenalles-Sant Llorenç Savall-Sant Feliu de Codines", "fitxer":"st_vicenc_castellet-obac-estenalles-st_llorenc_savall-st_feliu_codines.gpx", "desc":"BTT*Sant Feliu*st_vicenc_castellet-obac-estenalles-st_llorenc_savall-st_feliu_codines.gpx*2022-12-24*50.9 Km*04:25:28*05:30:32*2610 m*1811 m*178m*946m*"},
			{"ruta":"Sant Feliu-Sant Llorenç-Vinardell-Castellterçol-Coll de Termes", "fitxer":"sant_feliu-sant_llorenc-vinardell-castelltercol-coll_termes.gpx", "desc":"BTT*Sant Feliu*sant_feliu-sant_llorenc-vinardell-castelltercol-coll_termes.gpx*2021-12-27*38.7 Km*03:22:00*03:39:00*1462 m*1186 m*473m*937m*"},
			{"ruta":"Sant Feliu-Sant Miquel del Fai-Sant Quirze Safaja", "fitxer":"sant_feliu-sant_miquel_del_fai-sant_quirze_safaja_v2.gpx", "desc":"ciclisme*Sant Feliu*sant_feliu-sant_miquel_del_fai-sant_quirze_safaja_v2.gpx*2023-07-24*23.2 Km*00:59:20*00:59:20*1027 m*819 m*455m*695m*"},
			{"ruta":"ToDo-St Feliu corriols", "fitxer":"todo-st_feliu-corriols.gpx", "desc":"BTT*Sant Feliu*todo-st_feliu-corriols.gpx*2021-04-10*15.2 Km*01:38:50*01:39:30*557 m*420 m*473 m*703 m*"},
			{"ruta":"Barcelona-Vall del Tenes-Sant Feliu", "fitxer":"barcelona-vall_del_tenes-sant_feliu.gpx", "desc":"BTT*Sant Feliu*barcelona-vall_del_tenes-sant_feliu.gpx*2022-04-24*56.0 Km*03:12:52*04:05:20*974 m*741 m*0m*481m*"},
			{"ruta":"Camí dels Feliuans a la Mola de Gallifa", "fitxer":"cami_feliuans-mola_gallifa.gpx", "desc":"BTT*Sant Feliu*cami_feliuans-mola_gallifa.gpx*2022-05-01*3.0 Km*00:50:29*01:08:13*375 m*330 m*516m*906m*"},
			{"ruta":"Volta per Sant Feliu 6", "fitxer":"volta_per_sant_feliu_6.gpx", "desc":"BTT*Sant Feliu*volta_per_sant_feliu_6.gpx*2022-08-21*12.7 Km*01:13:36*01:14:28*526 m*429 m*473m*842m*"},
			{"ruta":"ToDo-Volta per Sant Feliu 5", "fitxer":"todo-volta_per_sant_feliu_5.gpx", "desc":"BTT*Sant Feliu*todo-volta_per_sant_feliu_5.gpx*2022-03-07*7.3 Km*00:00:00*00:54:20*260 m*179 m*472m*640m*"},
			{"ruta":"Barcelona - Sant Feliu", "fitxer":"bcn_stfeliu_1.gpx", "desc":"BTT*Sant Feliu*bcn_stfeliu_1.gpx*2013-07-13*55.8 Km*00:25:13 *00:25:13*1466 m*1048 m*101m*674m*vYvE9GLMAwv"},
			{"ruta":"Sant Feliu-La Garriga", "fitxer":"st_feliu-la_garriga.gpx", "desc":"BTT*Sant Feliu*st_feliu-la_garriga.gpx*2012-11-04*25.4 Km*01:24:47*01:59:25*686 m*581 m*302m*893m*v1OwMKVx5Xq"},
			{"ruta":"Sant Feliu de C-Riera de Caldes-Riu Besós-Sta Coloma de Gr", "fitxer":"st_feliu_de_c-sta_coloma_de_gr.gpx", "desc":"BTT*Sant Feliu*st_feliu_de_c-sta_coloma_de_gr.gpx*2020-03-01*40.7 Km*02:05:53 *02:43:06*357 m*111 m*11m*483m*vRO7d4pNXK6"},
			{"ruta":"Sant Feliu-Sant Quirze Safaja-Sant Miquel del Fai", "fitxer":"st_feliu_c-st_quirze_safaja-st_miquel_del_fai.gpx", "desc":"ciclisme*Sant Feliu*st_feliu_c-st_quirze_safaja-st_miquel_del_fai.gpx*2023-07-20*23.3 Km*01:02:23*01:03:32*1036 m*847 m*440m*696m*"},
			{"ruta":"Manresa-Sant Feliu", "fitxer":"manresa-sant_feliu.gpx", "desc":"BTT*Sant Feliu*manresa-sant_feliu.gpx*2022-09-17*64.6 Km*04:38:44*05:31:40*2243 m*1650 m*201m*942m*"},
			{"ruta":"Sant Feliu-Caldes-Sentmenat-Castellar-Can Cadafalch-Gallifa", "fitxer":"sant_feliu-caldes-sentmenat-castellar-can_cadafalch-gallifa.gpx", "desc":"BTT*Sant Feliu*sant_feliu-caldes-sentmenat-castellar-can_cadafalch-gallifa.gpx*2022-08-29*40.5 Km*02:25:24*02:43:52*1266 m*912 m*206m*657m*"},
			{"ruta":"Caldes de Monbui-Castell de Montbui-St Feliu-St Quirze", "fitxer":"caldes_monbui-castell_montbui-st_feliu-st_quirze.gpx", "desc":"BTT*Sant Feliu*caldes_monbui-castell_montbui-st_feliu-st_quirze.gpx*2023-09-03*26.4 Km*02:10:59*03:05:27*1031 m*924 m*235m*709m*"},
			{"ruta":"Sant Feliu-La Garriga v2", "fitxer":"st_feliu-la_garriga_v2.gpx", "desc":"BTT*Sant Feliu*st_feliu-la_garriga_v2.gpx*2021-04-09*25.6 Km*02:03:04*02:27:04*936 m*722 m*242 m*832 m*"},
			{"ruta":"Centelles-Collsuspina-Popa-St Feliu de Codines", "fitxer":"Centelles-Collsuspina-Popa-St_Feliu_de_Codines.gpx", "desc":"BTT*Sant Feliu*Centelles-Collsuspina-Popa-St_Feliu_de_Codines.gpx*2020-12-24*35.6 Km*02:56:36*03:13:12*1071 m*798 m*475m*946m*"},
			{"ruta":"Terrassa-Sant Feliu de Codines", "fitxer":"Terrassa-Sant_Feliu_de_Codines.gpx", "desc":"BTT*Sant Feliu*Terrassa-Sant_Feliu_de_Codines.gpx*2020-02-02*34.7 Km*02:34:50 *02:50:15*1253 m*1040 m*269m*644m*vQvyz5M5yKO"},
			{"ruta":"Castellterçol, Sant Julià d'Úixols i Sant Quirze Safaja", "fitxer":"castelltercol-sant-julia-duixols-i-sant-quirze-safaja.gpx", "desc":"BTT*Sant Feliu*castelltercol-sant-julia-duixols-i-sant-quirze-safaja.gpx*2011-09-18*15.5 Km*22:22:32 *22:22:32*552 m*456 m*604m*893m*v1OwMKVPmXq"},
			{"ruta":"St_Feliu_Codines-Poses-Matafaluga-Gallifa", "fitxer":"St_Feliu_Codines-Poses-Matafaluga-Gallifa.gpx", "desc":"BTT*Sant Feliu*St_Feliu_Codines-Poses-Matafaluga-Gallifa.gpx*2020-07-18*37.9 Km*02:27:36*02:39:48*1171 m*887 m*471m*942m*vQvyD1NxNKq"},
			{"ruta":"Volta per Sant Feliu 1", "fitxer":"volta_per_st_feliu_1.gpx", "desc":"BTT*Sant Feliu*volta_per_st_feliu_1.gpx*2020-12-07*12.4 Km*01:03:32*01:03:52*450 m*367 m*362m*560m*"},
			{"ruta":"Girona-Els Àngels", "fitxer":"girona-els_angels.gpx", "desc":"ciclisme*Sant Feliu*girona-els_angels.gpx*2024-03-16*24.0 Km*01:12:04*01:27:19*901 m*764 m*73m*475m*"},
			{"ruta":"Sant Feliu-Castellterçol-Llogari-Granera", "fitxer":"can_deu-termes-uixols-castelltercol-llogari-granera-gallifa.gpx", "desc":"BTT*Sant Feliu*can_deu-termes-uixols-castelltercol-llogari-granera-gallifa.gpx*2021-08-19*50.7 Km*03:24:24*03:39:48*1569 m*1264 m*470m*894m*"},
			{"ruta":"Sta Coloma Gr-Vall del Tenes-St Feliu", "fitxer":"sta_coloma-tenes-st_feliu.gpx", "desc":"BTT*Sant Feliu*sta_coloma-tenes-st_feliu.gpx*2021-03-30*43.8 Km*02:55:44*02:56:58*841 m*601 m*13 m*480 m*"},
			{"ruta":"Sant Feliu-Sant Miquel del Fai-Camí a Llobateres", "fitxer":"sant_feliu-sant_miquel_del_fai-cami_a_llobateres.gpx", "desc":"BTT*Sant Feliu*sant_feliu-sant_miquel_del_fai-cami_a_llobateres.gpx*2021-08-30*15.4 Km*01:56:52*01:57:19*551 m*407 m*472m*656m*"},
			{"ruta":"Volta per Sant Feliu 4", "fitxer":"volta_per_sant_feliu_4.gpx", "desc":"senderisme*Sant Feliu*volta_per_sant_feliu_4.gpx*2021-12-25*6.7 Km*01:55:44*02:07:28*324 m*295 m*414m*566m*"},
			{"ruta":"Vacarisses-Obac-St Llorenc Savall-Gallifa-St Feliu", "fitxer":"Vacarisses-Obac-St_Llorenc_Savall-Gallifa-St_Feliu.gpx", "desc":"BTT*Sant Feliu*Vacarisses-Obac-St_Llorenc_Savall-Gallifa-St_Feliu.gpx*2021-05-08*45.0 Km*03:03:32*03:06:56*1461 m*1177 m*324m*760m*"},
			{"ruta":"Sant Feliu de Codines-Terrassa", "fitxer":"Sant_feliu_codines-Terrassa.gpx", "desc":"BTT*Sant Feliu*Sant_feliu_codines-Terrassa.gpx*2020-02-09*39.4 Km*02:44:36 *03:12:44*1201 m*932 m*273m*636m*vXvLYm5E97O"},
			{"ruta":"Volta a Sant Feliu 3", "fitxer":"volta_sant_feliu_3.gpx", "desc":"BTT*Sant Feliu*volta_sant_feliu_3.gpx*2021-08-17*31.9 Km*02:52:40*02:58:16*1002 m*767 m*318m*559m*"},
			{"ruta":"Volta per Sant Feliu 2", "fitxer":"volta_per_sant_feliu_2.gpx", "desc":"BTT*Sant Feliu*volta_per_sant_feliu_2.gpx*2021-07-03*15.4 Km*01:11:28*01:11:28*523 m*398 m*318m*533m*"},
			{"ruta":"Sant Feliu de C-Sant Vicenç de Castellet", "fitxer":"sant_feliu_de_c-sant_vicenc_de_castellet.gpx", "desc":"ciclisme*Sant Feliu*sant_feliu_de_c-sant_vicenc_de_castellet.gpx*2021-09-25*47.9 Km*02:14:40*02:14:40*1038 m*706 m*175m*651m*"},
			{"ruta":"Sant Feliu-Soler del Coll-Centelles-Popa-Sant Feliu", "fitxer":"sant_feliu-soler_del_coll-centelles-popa-sant_feliu.gpx", "desc":"BTT*Sant Feliu*sant_feliu-soler_del_coll-centelles-popa-sant_feliu.gpx*2022-02-27*49.5 Km*03:19:24*03:36:31*1956 m*1300 m*456m*891m*"},
			{"ruta":"Sant Feliu-Can Déu-Collet dels Termes", "fitxer":"st_feliu-can_deu-collet_termes.gpx", "desc":"BTT*Sant Feliu*st_feliu-can_deu-collet_termes.gpx*2024-03-28*13.8 Km*01:09:44*01:10:50*665 m*603 m*468m*836m*"},
			{"ruta":"Can Déu del Veïnat-Collet de Termes-Coll de Poses-Camí dels Horts", "fitxer":"can_deu_del_veinat-collet_de_termes-coll_de_poses-cami_dels_horts.gpx", "desc":"BTT*Sant Feliu*can_deu_del_veinat-collet_de_termes-coll_de_poses-cami_dels_horts.gpx*2021-09-19*14.7 Km*01:30:48*01:30:48*526 m*445 m*465m*840m*"},
			{"ruta":"Centelles-Collsuspina-Sta Coloma S-Castellterçol-St Julià Uixols-St Feliu", "fitxer":"centelles-collsus-sta_col_sasserra-castelltercol-uixols.gpx", "desc":"BTT*Sant Feliu*centelles-collsus-sta_col_sasserra-castelltercol-uixols.gpx*2021-02-21*41.0 Km*03:07:16*03:20:44*1170 m*960 m*471m*939m*"},
			{"ruta":"Sant Feliu de Codines-Caldes-Sentmenat-Castellar-St Sebastià de Montmajor", "fitxer":"st_feliu-caldes-castellar-montmajor.gpx", "desc":"BTT*Sant Feliu*st_feliu-caldes-castellar-montmajor.gpx*2020-06-07*38.9 Km*02:53:22 *03:02:33*1345 m*1061 m*201m*621m*v8qV125GE76"},
			{"ruta":"Sant Feliu-Collsuspina", "fitxer":"sant_feliu-collsuspina.gpx", "desc":"BTT*Sant Feliu*sant_feliu-collsuspina.gpx*2021-08-22*46.5 Km*03:20:59*03:31:47*1177 m*893 m*472m*916m*"},
			{"ruta":"NO-Volta per Sant Feliu 6", "fitxer":"NO-volta_per_sant_feliu_6.gpx", "desc":"BTT*Sant Feliu*NO-volta_per_sant_feliu_6.gpx*2022-08-27*12.0 Km*01:12:56*01:12:56*523 m*433 m*481m*843m*"},
			{"ruta":"Sant Feliu de C-Castellterçol-Granera-Sant Llorenç Savall", "fitxer":"sant_feliu_c-castelltercol-granera-sant_llorenc_savall.gpx", "desc":"ciclisme*Sant Feliu*sant_feliu_c-castelltercol-granera-sant_llorenc_savall.gpx*2023-07-19*46.9 Km*02:00:53*02:02:46*1526 m*1271 m*467m*793m*"}
		]},
		{"zona":"Bagà","id":"baga","rutes":[
			{"ruta":"Coll de Pal-Comafloriu-Xalet Coll de Pal", "fitxer":"coll_pal-comafloriu-xalet_coll_de_pal.gpx", "desc":"senderisme*Bagà*coll_pal-comafloriu-xalet_coll_de_pal.gpx*2021-01-02*4.0 Km*01:44:04*01:54:20*217 m*188 m*1926m*2205m*"},
			{"ruta":"ToDo-Bagà-Rebost-Gavarrós-Castellar de n'Hug-Gombrèn-Campdevànol", "fitxer":"todo-baga-rebost-gavarros-castellar_de_nhug-gombren-campdevanol.gpx", "desc":"BTT*Bagà*todo-baga-rebost-gavarros-castellar_de_nhug-gombren-campdevanol.gpx*2021-08-31*51.1 Km*01:03:20*01:03:20*1813 m*1470 m*731m*1722m*"},
			{"ruta":"Bac de Diví-Paller", "fitxer":"bac_de_divi-paller.gpx", "desc":"senderisme*Bagà*bac_de_divi-paller.gpx*2021-05-22*5.0 Km*02:04:00*02:29:00*601 m*534 m*922m*1393m*"},
			{"ruta":"Bagà-Coll de Turbians-Coll de la Bena-St Martí del Puig", "fitxer":"baga-coll_turbians-coll_bena-st_marti_puig.gpx", "desc":"BTT*Bagà*baga-coll_turbians-coll_bena-st_marti_puig.gpx*2020-06-20*25.4 Km*02:07:36*02:36:46*1107 m*934 m*772m*1458m*vXvLYz55r7O"},
			{"ruta":"Via Nicolau", "fitxer":"via_nicolau.gpx", "desc":"BTT*Bagà*via_nicolau.gpx*2020-07-10*4.5 Km*00:29:36 *00:50:28*290 m*191 m*771m*934m*"},
			{"ruta":"Transpirenaica en BTT Etapa 5 Bagà-Noves de Segre", "fitxer":"transpirenaica-en-btt-etapa-5-baga-noves-de-segre.gpx", "desc":"BTT*Bagà*transpirenaica-en-btt-etapa-5-baga-noves-de-segre.gpx*2019-07-30*66.3 Km*00:00:20 *19:21:01*2478 m*1781 m*580m*1916m*vPv4JZKn8R6"},
			{"ruta":"Campdevànol-Sant Jaume de Frontanyà-Cerdanyola-Bagà", "fitxer":"campdevanol-st_jaume_frontanya-cerdanyola-baga.gpx", "desc":"BTT*Bagà*campdevanol-st_jaume_frontanya-cerdanyola-baga.gpx*2022-12-17*51.4 Km*05:00:00*05:03:48*2284 m*1383 m*725m*1555m*"},
			{"ruta":"Bagà-Coll de Pal-Gavarrós", "fitxer":"baga-coll_de_pal-gavarros.gpx", "desc":"BTT*Bagà*baga-coll_de_pal-gavarros.gpx*2022-08-06*39.8 Km*02:58:56*03:20:32*1948 m*1603 m*724m*2110m*"},
			{"ruta":"Bagà-Collet d'Aïna-El Jou-Fumanya-Vallcebre", "fitxer":"baga-collet_aina-jou-fumanya-vallcebre.gpx", "desc":"ciclisme*Bagà*baga-collet_aina-jou-fumanya-vallcebre.gpx*2020-08-05*40.1 Km*02:34:44 *02:47:16*1339 m*1099 m*701m*1566m*vrqoM8DDZKO"},
			{"ruta":"Bagà, Brocà, Collada Grossa, Paller de Dalt, Paller de Baix, Bagà", "fitxer":"baga-broca-collada-grossa-paller-de-dalt-paller-de-baix-baga.gpx", "desc":"BTT*Bagà*baga-broca-collada-grossa-paller-de-dalt-paller-de-baix-baga.gpx*2019-08-20*19.4 Km*01:39:12*02:17:21*1081 m*868 m*768 m*1550 m*vWqBed1BjY6"},
			{"ruta":"Bagà-Pla_Castell-Collada_Grossa-Refugi_Rebost-Millarès", "fitxer":"Baga-Pla_Castell-Collada_Grossa-Refugi_Rebost-Millares.gpx", "desc":"BTT*Bagà*Baga-Pla_Castell-Collada_Grossa-Refugi_Rebost-Millares.gpx*2020-08-03*29.0 Km*02:46:20 *03:31:24*1594 m*1352 m*795m*1725m*vXOnMG5xX5v"},
			{"ruta":"Bagà-Via del Nicolau-Coll de Turbians", "fitxer":"baga-via_nicolau-coll_turbians.gpx", "desc":"BTT*Bagà*baga-via_nicolau-coll_turbians.gpx*2021-12-16*49.0 Km*03:20:08*03:54:08*1571 m*1107 m*162m*617m*"},
			{"ruta":"Bagà-Coll de Pal", "fitxer":"baga-coll_de_pal.gpx", "desc":"ciclisme*Bagà*baga-coll_de_pal.gpx*2021-08-26*38.3 Km*04:04:56*04:04:56*1611 m*1321 m*820m*2107m*"},
			{"ruta":"L'Hospitalet-Coll de Jou-Penyes Altes-Canal de la Serp-Gréixer", "fitxer":"l_hospitalet-coll_jou-penyes_altes-canal_serp-greixer.gpx", "desc":"senderisme*Bagà*l_hospitalet-coll_jou-penyes_altes-canal_serp-greixer.gpx*2023-09-29*14.7 Km*17:42:17*18:09:12*1952 m*1447 m*1014m*2244m*"},
			{"ruta":"Entreno Bagà 1", "fitxer":"entreno_baga_1.gpx", "desc":"BTT*Bagà*entreno_baga_1.gpx*2020-07-07*17.9 Km*01:25:56 *01:33:16*908 m*624 m*730m*1113m*vMq5dmN8586"},
			{"ruta":"Manresa-Berga-Bagà", "fitxer":"manresa-berga-baga.gpx", "desc":"BTT*Bagà*manresa-berga-baga.gpx*2024-02-09*95.2 Km*07:51:54*07:52:12*3268 m*2240 m*207m*1046m*"},
			{"ruta":"Bagà-Coll de Pal", "fitxer":"baga-coll_de_pal_v2.gpx", "desc":"ciclisme*Bagà*baga-coll_de_pal_v2.gpx*2023-08-17*35.3 Km*03:47:34*04:01:12*1981 m*1314 m*818m*2110m*"},
			{"ruta":"Campdevànol-Coll de Merolla-Bagà", "fitxer":"campdevanol-coll_merolla-baga.gpx", "desc":"ciclisme*Bagà*campdevanol-coll_merolla-baga.gpx*2023-07-26*34.8 Km*01:48:19*02:08:23*1322 m*1070 m*724m*1112m*"},
			{"ruta":"Bagà-La Pobla de Lillet", "fitxer":"baga-la_pobla_de_lillet.gpx", "desc":"BTT*Bagà*baga-la_pobla_de_lillet.gpx*2023-07-27*32.3 Km*02:08:40*02:41:03*1142 m*937 m*724m*987m*"},
			{"ruta":"Cal Cerdanyola-Monnell-Murcurols-El Puig", "fitxer":"cal_cerdanyola-monnell-murcurols-el_puig.gpx", "desc":"senderisme*Bagà*cal_cerdanyola-monnell-murcurols-el_puig.gpx*2021-01-01*7.9 Km*02:24:19*02:37:55*530 m*458 m*915m*1192m*"},
			{"ruta":"Bagà-Coll de la Bena-Turbians-Roca de Cans-Siti", "fitxer":"baga-coll_bena-turbians-roca_de_cans-siti.gpx", "desc":"BTT*Bagà*baga-coll_bena-turbians-roca_de_cans-siti.gpx*2021-05-21*27.6 Km*03:08:24*03:14:48*1437 m*1166 m*771m*1472m*"},
			{"ruta":"Bagà-Gréixer-Coll d'Escriu-Empedrats-Bagà", "fitxer":"baga-greixer-escriu-empedrats-baga.gpx", "desc":"BTT*Bagà*baga-greixer-escriu-empedrats-baga.gpx*2013-03-26*16.5 Km*00:48:52 *02:11:07*1023 m*730 m*796m*1503m*vXOnEkVyQBv"},
			{"ruta":"Bagà-Bena-Bauma-Collell-Saldes-Riu de Saldes", "fitxer":"baga-bena-bauma-collell-saldes-riu_de_saldes.gpx", "desc":"BTT*Bagà*baga-bena-bauma-collell-saldes-riu_de_saldes.gpx*2022-09-23*55.3 Km*03:50:33*05:16:47*2898 m*1779 m*710m*1924m*"},
			{"ruta":"Volta per Brocà (sector sud)", "fitxer":"volta_broca_sector_sud.gpx", "desc":"senderisme*Bagà*volta_broca_sector_sud.gpx*2020-07-10*7.2 Km*01:41:19 *02:21:19*297 m*224 m*745m*896m*"},
			{"ruta":"Bagà-Riugréixer-Càmping", "fitxer":"riugreixer-camping.gpx", "desc":"BTT*Bagà*riugreixer-camping.gpx*2021-08-24*4.7 Km*00:22:36*00:22:36*180 m*117 m*798m*895m*"},
			{"ruta":"Xalet Coll de Pal-Gavarrós-Guardiola-Bagà-L'Hospitalet", "fitxer":"xalet_coll_de_pal-gavarros-guardiola-baga-l_hospitalet.gpx", "desc":"BTT*Bagà*xalet_coll_de_pal-gavarros-guardiola-baga-l_hospitalet.gpx*2023-06-03*33.6 Km*03:25:31*06:20:38*1878 m*1648 m*724m*1990m*"},
			{"ruta":"Bagà-Coll de la Pelosa", "fitxer":"baga-coll_de_la_pelosa.gpx", "desc":"BTT*Bagà*baga-coll_de_la_pelosa.gpx*2021-05-22*22.0 Km*01:58:28*01:58:28*943 m*716 m*798m*1421m*"},
			{"ruta":"Bagà-Brocà-Collada Grossa-L'Estret-Riutort", "fitxer":"baga-broca-collada_grossa-estret-riutort.gpx", "desc":"BTT*Bagà*baga-broca-collada_grossa-estret-riutort.gpx*2020-06-21*26.8 Km*02:22:47*03:10:57*1179 m*1029 m*747m*1551m*vNOPnVDzrYq"},
			{"ruta":"Bagà-Rebost-Gavarrós-Coll Roig-Collet de la Casa Nova-Sant Vicenç de Rus-La Pobla-Guardiola", "fitxer":"baga-rebost-gavarros-coll_roig-collet_casa_nova-sant_vicenc_de_rus-la_pobla-guardiola.gpx", "desc":"BTT*Bagà*baga-rebost-gavarros-coll_roig-collet_casa_nova-sant_vicenc_de_rus-la_pobla-guardiola.gpx*2020-11-07*43.0 Km*03:53:11*04:03:47*1810 m*1426 m*720m*1723m"},
			{"ruta":"Paller-Sant Marc de Brocà", "fitxer":"paller-sant_marc_de_broca.gpx", "desc":"senderisme*Bagà*paller-sant_marc_de_broca.gpx*2023-10-14*11.3 Km*02:45:50*03:00:25*1037 m*871 m*978m*1601m*"},
			{"ruta":"Monnell-Vimboca-Moixa-Afrau dels Cortals", "fitxer":"monnell-vimboca-moixa-afrau_dels_cortals.gpx", "desc":"senderisme*Bagà*monnell-vimboca-moixa-afrau_dels_cortals.gpx*2024-05-12*28.5 Km*07:09:50*09:55:37*3005 m*2376 m*961m*2218m*"},
			{"ruta":"Bagà-Gresolet-Riu de Saldes", "fitxer":"baga-gresolet-riu_saldes.gpx", "desc":"BTT*Bagà*baga-gresolet-riu_saldes.gpx*2020-07-08*43.5 Km*03:02:44 *03:37:02*1754 m*1267 m*702m*1575m*vYvrDG22zx6"},
			{"ruta":"Bagà-Gavarrós-Rebost", "fitxer":"baga-gavarros-rebost.gpx", "desc":"BTT*Bagà*baga-gavarros-rebost.gpx*2023-07-31*28.1 Km*02:15:21*02:42:56*1520 m*1306 m*724m*1732m*"},
			{"ruta":"Bagà-Coll de Merolla-Campdevànol", "fitxer":"baga-coll_de_merolla-campdevanol_v2.gpx", "desc":"ciclisme*Bagà*baga-coll_de_merolla-campdevanol_v2.gpx*2023-08-01*33.0 Km*01:32:56*01:32:56*1231 m*1015 m*725m*1111m*"},
			{"ruta":"NO-via_del_nicolau", "fitxer":"NO-via_del_nicolau.gpx", "desc":"BTT*Bagà*NO-via_del_nicolau.gpx*2022-08-04*13.0 Km*01:21:16*02:12:56*593 m*404 m*733m*930m*"},
			{"ruta":"Gréixer-Coll d'Escriu", "fitxer":"greixer-coll_d_escriu.gpx", "desc":"BTT*Bagà*greixer-coll_d_escriu.gpx*2022-08-07*7.3 Km*01:28:28*02:00:40*605 m*531 m*1016m*1505m*"},
			{"ruta":"Bagà-Pujada antena", "fitxer":"baga-pujada_antena.gpx", "desc":"BTT*Bagà*baga-pujada_antena.gpx*2022-08-03*7.0 Km*00:40:52*00:40:52*393 m*312 m*811m*1111m*"},
			{"ruta":"Bagà-Cerdanyola-Sant Jaume de Frontanyà-Campdevànol", "fitxer":"baga-cerdanyola-st_jaume_frontanya-campdevanol.gpx", "desc":"BTT*Bagà*baga-cerdanyola-st_jaume_frontanya-campdevanol.gpx*2024-02-23*50.7 Km*04:01:18*04:46:34*2317 m*2042 m*724m*1554m*"},
			{"ruta":"Bagà-Coll de Merolla-Campdevànol", "fitxer":"baga-coll_merolla-campdevanol.gpx", "desc":"ciclisme*Bagà*baga-coll_merolla-campdevanol.gpx*2021-07-18*34.9 Km*01:43:19*01:43:19*848 m*451 m*721m*1100m*"},
			{"ruta":"Bagà-Sant Joan-Matallops-Vinyavella", "fitxer":"baga-sant_joan-matallops-vinyavella.gpx", "desc":"BTT*Bagà*baga-sant_joan-matallops-vinyavella.gpx*2022-08-01*11.7 Km*01:17:32*02:07:08*535 m*411 m*780m*1037m*"},
			{"ruta":"Alp-Masella-Molina-Creueta-Bagà", "fitxer":"alp-masella-molina-creueta-baga.gpx", "desc":"ciclisme*Bagà*alp-masella-molina-creueta-baga.gpx*2023-01-03*55.8 Km*02:24:05*02:24:05*1741 m*1078 m*724m*1926m*"},
			{"ruta":"Paller de Baix-Paller de Dalt", "fitxer":"paller_de_baix-paller_de_dalt.gpx", "desc":"BTT*Bagà*paller_de_baix-paller_de_dalt.gpx*2022-08-02*7.5 Km*01:04:36*01:16:52*559 m*413 m*975m*1310m*"},
			{"ruta":"Cambra del Boc", "fitxer":"cambra_del_boc.gpx", "desc":"senderisme*Bagà*cambra_del_boc.gpx*2020-08-06*9.0 Km*02:21:36 *04:22:40*1190 m*946 m*943m*1756m*vLqe1GPEzRq"},
			{"ruta":"Bagà-Cap del Tossal", "fitxer":"baga-cap_del_tossal.gpx", "desc":"BTT*Bagà*baga-cap_del_tossal.gpx*2020-07-01*19.5 Km*01:08:32*02:30:45*961 m*800 m*705m*1349m*vrqDmZAyGwv"}
		]},
		{"zona":"Altres","id":"altres","rutes":[
			{"ruta":"KKH-Karakorum Highway", "fitxer":"KKH.gpx", "desc":"BTT*Altres*KKH.gpx*1997-08-01*795.4 Km*04:45:30*04:45:30*14012 m*6123 m*1025m*4705m*"},
			{"ruta":"Els tres refugis: Coma de Vaca, Ull de Ter, Ras de Carançà", "fitxer":"els_tres_refugis.gpx", "desc":"senderisme*Altres*els_tres_refugis.gpx*2020-08-07*49.8 Km*01:54:12 *12:27:17*4053 m*3378 m*1133m*2722m*coma_de_vaca-ull_de_ter_ras_de_caranca"},
			{"ruta":"Sender arqueològic d'Eina", "fitxer":"sender_arqueologic_eyna.gpx", "desc":"senderisme*Altres*sender_arqueologic_eyna.gpx*2024-02-25*5.8 Km*01:02:13*02:08:25*201 m*171 m*1501m*1622m*"},
			{"ruta":"Mollet-Gallecs-Santa Perpètua", "fitxer":"mollet-gallecs-sta_perpetua.gpx", "desc":"BTT*Altres*mollet-gallecs-sta_perpetua.gpx*2022-09-15*18.2 Km*01:25:32*01:36:04*284 m*240 m*69m*140m*"},
			{"ruta":"Santa Eugènia-Ridolaina-Nas-Olià", "fitxer":"santa_eugenia-ridolaina-nas-olia.gpx", "desc":"senderisme*Altres*santa_eugenia-ridolaina-nas-olia.gpx*2022-08-25*7.2 Km*00:00:00*09:32:00*332 m*301 m*1048m*1255m*"},
			{"ruta":"Congost de Mont-rebei", "fitxer":"congost_mont-rebei.gpx", "desc":"senderisme*Altres*congost_mont-rebei.gpx*2021-12-07*10.5 Km*03:02:44*04:12:28*997 m*736 m*496m*857m*"},
			{"ruta":"Montnegre Open MTB 32Km", "fitxer":"montnegreopenmtb_32.gpx", "desc":"BTT*Altres*montnegreopenmtb_32.gpx*2020-11-05*29.4 Km*02:25:40 *02:58:01*1102 m*884 m*138m*644m*vRO7RomyRyv"},
			{"ruta":"Àger-Coll d'Ares-Alsamora-Mont-rebei-La Pertusa-Corçà", "fitxer":"ager-coll_ares-alsamora-mont-rebei-la_pertusa-corca.gpx", "desc":"BTT*Altres*ager-coll_ares-alsamora-mont-rebei-la_pertusa-corca.gpx*2008-11-18*50.5 Km*02:09:10*02:10:40*2311 m*1537 m*496m*1528m*"},
			{"ruta":"Sant Salvador de les Espases", "fitxer":"sant_salvador_de_les_espases.gpx", "desc":"senderisme*Altres*sant_salvador_de_les_espases.gpx*2021-11-01*4.2 Km*01:30:42*02:04:38*445 m*360 m*94m*404m"},
			{"ruta":"Vic-Viladrau-Vic", "fitxer":"vic-viladrau-vic.gpx", "desc":"BTT*Altres*vic-viladrau-vic.gpx*2022-11-11*49.2 Km*04:10:54*04:58:31*1496 m*1136 m*472m*866m*"},
			{"ruta":"Blanes-Gavarres-Girona", "fitxer":"blanes-gavarres-girona.gpx", "desc":"BTT*Altres*blanes-gavarres-girona.gpx*2023-03-10*84.5 Km*05:50:36*07:06:32*3023 m*2332 m*10m*458m*"},
			{"ruta":"Serra d'Urbasa", "fitxer":"serra_urbasa.gpx", "desc":"BTT*Altres*serra_urbasa.gpx*1995-07-06*27.5 Km*00:25:10*00:25:10*394 m*238 m*857m*1083m*"},
			{"ruta":"Maresme Challenge 2013", "fitxer":"maresme_challenge_2013.gpx", "desc":"BTT*Altres*maresme_challenge_2013.gpx*2013-04-06*39.1 Km*02:38:23 *05:06:23*1633 m*1178 m*26m*446m*vXOd3mjVNkv"},
			{"ruta":"Artesa de Segre-Montmagastre", "fitxer":"artesa_de_segre-montmagastre.gpx", "desc":"BTT*Altres*artesa_de_segre-montmagastre.gpx*2023-08-31*44.4 Km*02:48:22*04:24:47*1062 m*900 m*299m*683m*"},
			{"ruta":"Manresa-Rellinars-Terrassa", "fitxer":"manresa-rellinars-terrassa.gpx", "desc":"BTT*Altres*manresa-rellinars-terrassa.gpx*2023-03-03*44.0 Km*03:04:48*03:48:52*1726 m*1295 m*160m*759m*"},
			{"ruta":"Fageda d'Irati", "fitxer":"fageda_irati.gpx", "desc":"BTT*Altres*fageda_irati.gpx*1995-07-08*17.8 Km*00:35:20*00:35:20*580 m*266 m*747m*980m*"},
			{"ruta":"Montnegre Open MTB 50Km", "fitxer":"montnegreopenmtb_50.gpx", "desc":"BTT*Altres*montnegreopenmtb_50.gpx*2021-03-19*49.1 Km*04:35:08*04:44:20*2058 m*1645 m*188m*634m*"},
			{"ruta":"Pujada a la Mola", "fitxer":"pujada_a_la_mola.gpx", "desc":"senderisme*Altres*pujada_a_la_mola.gpx*2020-12-31*5.5 Km*02:39:20*03:17:26*491 m*450 m*698m*1087m*"},
			{"ruta":"Sant Celoni-Turo de l'Home v2", "fitxer":"sant_celoni-turo_de_lhome_v2.gpx", "desc":"BTT*Altres*sant_celoni-turo_de_lhome_v2*2024-04-19*51.6 Km*03:46:22*05:03:51*2476 m*2121 m*131m*1680m*"},
			{"ruta":"Centelles-Collsuspina-Canovelles", "fitxer":"centelles-collsuspina-canovelles.gpx", "desc":"BTT*Altres*centelles-collsuspina-canovelles.gpx*2023-06-09*63.7 Km*03:40:41*04:34:17*1899 m*1552 m*148m*1017m*"},
			{"ruta":"Manresa-La Sala-Callús-Castellnou-Santpedor", "fitxer":"manresa-la_sala-callus-castellnou-santpedor.gpx", "desc":"BTT*Altres*manresa-la_sala-callus-castellnou-santpedor.gpx*2021-10-23*61.3 Km*04:32:32*05:30:36*1614 m*1298 m*201m*585m"},
			{"ruta":"Riells-Turó de les Onze Hores", "fitxer":"riells-turo_de_les_onze_hores.gpx", "desc":"senderisme*Altres*riells-turo_de_les_onze_hores.gpx*2023-03-19*8.3 Km*02:04:07*02:13:47*717 m*615 m*371m*739m*"},
			{"ruta":"Montcau", "fitxer":"montcau.gpx", "desc":"senderisme*Altres*montcau.gpx*2022-12-04*3.1 Km*00:58:44*01:27:44*204 m*189 m*876m*1027m*"},
			{"ruta":"Embassament d'Irabia, Fageda d'Irati", "fitxer":"embassament_irabia.gpx", "desc":"BTT*Altres*embassament_irabia.gpx*1995-07-09*13.2 Km*00:24:20*00:24:20*433 m*215 m*789m*979m*"},
			{"ruta":"Lluçanès Feréstec 2019 (variant)", "fitxer":"llucanes_ferestec_2019.gpx", "desc":"BTT*Altres*llucanes_ferestec_2019.gpx*2022-04-10*61.5 Km*06:19:12*07:26:00*1896 m*1487 m*503m*889m*"},
			{"ruta":"Ribes-Coll de Jou-Taga-Ogassa-Camprodon-Abella-Collada Verda-Ribes", "fitxer":"ribes-coll_de_jou-taga-ogassa-camprodon-abella-collada_verda-ribes.gpx", "desc":"BTT*Altres*ribes-coll_de_jou-taga-ogassa-camprodon-abella-collada_verda-ribes.gpx*2022-11-18*61.4 Km*06:14:04*06:41:05*3389 m*2583 m*906m*2017m*"},
			{"ruta":"Bardenas Reales", "fitxer":"bardenas_reales.gpx", "desc":"BTT*Altres*bardenas_reales.gpx*1995-07-10*50.3 Km*01:21:30*01:21:30*745 m*555 m*272m*470m*"},
			{"ruta":"Sant Vicenç de Calders-Martorell", "fitxer":"sant_vicenc_de_calders-martorell.gpx", "desc":"BTT*Altres*sant_vicenc_de_calders-martorell.gpx*2022-11-25*60.9 Km*03:07:48*03:56:26*859 m*701 m*11m*256m*"},
			{"ruta":"Manresa (Bages Sud)", "fitxer":"manresa_bages_sud.gpx", "desc":"BTT*Altres*manresa_bages_sud.gpx*2023-02-24*63.2 Km*04:51:04*05:10:32*2199 m*1645 m*149m*777m*"},
			{"ruta":"Blanes-Mataró", "fitxer":"blanes-mataro.gpx", "desc":"BTT*Altres*blanes-mataro.gpx*2022-03-24*56.4 Km*03:47:48*04:23:28*2429 m*1660 m*7m*596m*"},
			{"ruta":"Lleida-Sant Vicenç de Calders", "fitxer":"lleida-st_vicenc_calders.gpx", "desc":"BTT*Altres*lleida-st_vicenc_calders.gpx*2024-04-12*131.6 Km*06:26:35*08:07:49*1939 m*1684 m*1m*634m*"},
			{"ruta":"La Portals 2013 (4a Volta a Montserrat)", "fitxer":"la-portals-2013-4a-volta-a-la-montanya-de-montserrat-en-btt.gpx", "desc":"BTT*Altres*la-portals-2013-4a-volta-a-la-montanya-de-montserrat-en-btt.gpx*2013-04-14*60.9 Km*03:20:24 *03:27:06*2289 m*1762 m*76m*718m*v1OwMKVPmXq"},
			{"ruta":"Sant Celoni-el Sui", "fitxer":"sant_celoni-el_sui.gpx", "desc":"BTT*Altres*sant_celoni-el_sui.gpx*2024-01-12*48.8 Km*03:28:45*04:12:43*1944 m*1637 m*155m*1302m*"},
			{"ruta":"Sant Vicenç de Calders-Tarragona-Salomó", "fitxer":"st_vicenc_calders-tarragona-salomo.gpx", "desc":"BTT*Altres*st_vicenc_calders-tarragona-salomo.gpx*2024-02-02*89.2 Km*05:24:55*07:21:17*1671 m*1447 m*6m*272m*"},
			{"ruta":"Vilafranca del P.-Sant Andreu de la B.", "fitxer":"vilafranca_del_p-sant_andreu_de_la_b.gpx", "desc":"BTT*Altres*vilafranca_del_p-sant_andreu_de_la_b.gpx*2021-10-02*39.8 Km*02:30:08*03:42:08*900 m*684 m*38m*566m*"},
			{"ruta":"Ulriken-Floyen-Bergen", "fitxer":"ulriken-floyen-bergen.gpx", "desc":"senderisme*Altres*ulriken-floyen-bergen.gpx*2023-08-12*20.8 Km*04:57:09*07:29:01*1227 m*1051 m*1m*659m*"},
			{"ruta":"Ruta del Císter-GR-175", "fitxer":"ruta_cister_gr_175.gpx", "desc":"BTT*Altres*ruta_cister_gr_175.gpx*2024-05-31*115.7 Km*08:32:25*10:36:24*3234 m*2506 m*294m*851m*"},
			{"ruta":"Selenika 2023 Manresa", "fitxer":"selenika_2023-manresa.gpx", "desc":"BTT*Altres*selenika_2023-manresa.gpx*2023-09-22*75.3 Km*04:28:56*07:09:07*1926 m*1523 m*201m*658m*"},
			{"ruta":"Manresa-Vilafranca del Penedès", "fitxer":"manresa-vilafranca_del_penedes.gpx", "desc":"BTT*Altres*manresa-vilafranca_del_penedes.gpx*2023-02-17*70.4 Km*04:17:00*05:18:36*1674 m*1353 m*142m*754m*"},
			{"ruta":"Santa Coloma de Gr-Sant Andreu de Llavaneres", "fitxer":"sta_coloma-st_andreu_llavaneres.gpx", "desc":"BTT*Altres*sta_coloma-st_andreu_llavaneres.gpx*2013-06-26*51.9 Km*03:10:26*03:59:53*1590 m*1369 m*25m*477m*"},
			{"ruta":"ToDo-Llacuna-Queralt-Sant Martí de Tous", "fitxer":"todo-llacuna-queralt-st_marti_de_tous.gpx", "desc":"BTT*Altres*todo-llacuna-queralt-st_marti_de_tous.gpx*2012-10-13*44.8 Km*01:39:28*04:39:58*1270 m*1031 m*432m*813m*"},
			{"ruta":"Sant Vicenç de Calders-la Pobla de Claramunt", "fitxer":"st_vicenc_calders-la_pobla_claramunt.gpx", "desc":"BTT*Altres*st_vicenc_calders-la_pobla_claramunt.gpx*2023-10-16*72.3 Km*04:24:27*06:00:09*2039 m*1762 m*9m*649m*"},
			{"ruta":"Sant Vicenç de Calders-la Pobla de Claramunt", "fitxer":"st_vicenc_calders-la_pobla_claramunt.gpx", "desc":"BTT*Altres*st_vicenc_calders-la_pobla_claramunt.gpx*2023-10-16*72.3 Km*04:24:27*06:00:09*2039 m*1762 m*9m*649m*"},
			{"ruta":"Orbea-Monegros 2024", "fitxer":"orbea-monegros-2024.gpx", "desc":"BTT*Altres*orbea-monegros-2024.gpx*2024-04-27*105.4 Km*04:34:34*05:19:08*1354 m*1145 m*192m*554m*"},
			{"ruta":"Terrassa-Obac-Sant Vicenç de Castellet", "fitxer":"terrassa-obac-st_vicenc_de_castellet.gpx", "desc":"BTT*Altres*terrassa-obac-st_vicenc_de_castellet.gpx*2023-03-31*36.8 Km*02:30:56*02:56:04*1449 m*1037 m*180m*678m*"},
			{"ruta":"Pessonada-Herba Savina", "fitxer":"pessonada-herba_savina.gpx", "desc":"senderisme*Altres*pessonada-herba_savina.gpx*2021-12-05*13.2 Km*04:06:31*04:06:44*688 m*571 m*862m*1032m*"},
			{"ruta":"Sant Celoni-Santa Coloma de Gr", "fitxer":"sant_celoni-santa_coloma_de_gr.gpx", "desc":"BTT*Altres*sant_celoni-santa_coloma_de_gr.gpx*2022-02-17*62.7 Km*04:11:16*04:35:16*2446 m*1830 m*28m*638m*"},
			{"ruta":"Cornellà-delta Llobregat-Viladecans", "fitxer":"cornella-delta_llobregat-viladecans.gpx", "desc":"BTT*Altres*cornella-delta_llobregat-viladecans.gpx*2023-11-03*31.9 Km*23:15:05*23:34:41*211 m*168 m*0m*37m*"},
			{"ruta":"La Sènia-Casetes Velles-Fredes-La Sènia", "fitxer":"la_senia-casetes_velles-fredes-la_senia.gpx", "desc":"BTT*Altres*la_senia-casetes_velles-fredes-la_senia.gpx*2012-10-01*63.9 Km*05:19:36*06:24:11*2580 m*1891 m*319m*1255m*"},
			{"ruta":"Cornellà-Sant Sadurní d'Anoia", "fitxer":"cornella-sant_sadurni_d_anoia.gpx", "desc":"BTT*Altres*cornella-sant_sadurni_d_anoia.gpx*2022-10-13*46.0 Km*03:07:32*03:43:44*1534 m*1193 m*3m*504m*"},
			{"ruta":"Tavèrnoles-Savassona", "fitxer":"tavernoles-savassona.gpx", "desc":"senderisme*Altres*tavernoles-savassona.gpx*2022-01-16*5.6 Km*01:20:44*01:48:24*281 m*239 m*538m*675m*"},
			{"ruta":"Maçanet-Montsoriu-Vic", "fitxer":"macanet-montsoriu-vic.gpx", "desc":"BTT*Altres*macanet-montsoriu-vic.gpx*2023-04-28*77.3 Km*04:54:00*06:48:00*2130 m*1918 m*85m*855m*"},
			{"ruta":"Llimiana, Serra Barcedana, Gercles", "fitxer":"llimiana-serra-barcedana-gercles.gpx", "desc":"BTT*Altres*llimiana-serra-barcedana-gercles.gpx*2013-08-20*32.2 Km*01:28:28 *03:16:37*1117 m*914 m*392m*1172m*vrqodjBjjKv"},
			{"ruta":"Selènika 2022", "fitxer":"selenika-2022.gpx", "desc":"BTT*Altres*selenika-2022.gpx*2023-03-24*70.2 Km*04:23:55*05:12:31*1883 m*1435 m*161m*398m*"},
			{"ruta":"Monegros Nord", "fitxer":"Monegros-nord.gpx", "desc":"BTT*Altres*Monegros-nord.gpx*2016-05-01*84.8 Km*05:04:02 *05:55:26*1493 m*1205 m*189m*556m*vwq17eZKVLO"},
			{"ruta":"ToDo-Calaf-Cellers-Quadrells", "fitxer":"todo-calaf-cellers-quadrells.gpx", "desc":"BTT*Altres*todo-calaf-cellers-quadrells.gpx*2020-12-18*50.1 Km*01:49:52*06:03:49*1312 m*1131 m*493m*920m*"},
			{"ruta":"Coll d'Estenalles-Mura", "fitxer":"coll_destenalles-mura.gpx", "desc":"senderisme*Altres*coll_destenalles-mura.gpx*2022-03-20*7.5 Km*02:03:12*02:20:24*202 m*147 m*466m*923m*"},
			{"ruta":"ToDo-Calaf-Rubió-Jorba-Copons", "fitxer":"todo-calaf-rubio-jorba-copons.gpx", "desc":"BTT*Altres*todo-calaf-rubio-jorba-copons.gpx*2014-07-06*44.4 Km*00:02:40*03:47:52*835 m*611 m*378m*769m*"},
			{"ruta":"Marxa del Montserratí 2024", "fitxer":"marxa_del_montserrati_2024.gpx", "desc":"senderisme*Altres*marxa_del_montserrati_2024.gpx*2024-02-04*16.3 Km*04:00:00*04:30:00*932 m*748 m*71m*397m*"},
			{"ruta":"Integral megalítica de Vallromanes", "fitxer":"integral_megalitica_vallromanes.gpx", "desc":"BTT*Altres*integral_megalitica_vallromanes.gpx*2021-03-05*46.0 Km*04:12:12*05:05:56*1511 m*1270 m*51m*444m*"},
			{"ruta":"Cueva de Ágreda-Moncayo", "fitxer":"cueva_de_agreda-moncayo.gpx", "desc":"senderisme*Altres*cueva_de_agreda-moncayo.gpx*2019-12-06*11.5 Km*04:24:31*04:24:31*994 m*955 m*1311m*2304m*"},
			{"ruta":"Volta Boumort", "fitxer":"volta-boumort.gpx", "desc":"BTT*Altres*volta-boumort.gpx*2022-10-01*77.8 Km*21:13:20*24:55:41*2875 m*2190 m*499m*1926m*"},
			{"ruta":"Monegros Sud", "fitxer":"Monegros-sud.gpx", "desc":"BTT*Altres*Monegros-sud.gpx*2012-12-29*72.5 Km*14:49:09 *00:32:16*563 m*430 m*307m*411m*v4OGm8Dz4mq"},
			{"ruta":"Garraf - Begues - Garraf", "fitxer":"garraf-begues-garraf.gpx", "desc":"BTT*Altres*garraf-begues-garraf.gpx*1998-03-31*38.5 Km*02:21:34 *03:35:10*1313 m*1073 m*15m*569m*vrqodwKyRKv"},
			{"ruta":"Pineta-Llanos del Larri-Cascada del Cinca", "fitxer":"pineta-llanos_del_larri-cascada_del_cinca.gpx", "desc":"senderisme*Altres*pineta-llanos_del_larri-cascada_del_cinca.gpx*2022-07-24*10.3 Km*00:00:00*06:10:08*540 m*463 m*1300m*1671m*"},
			{"ruta":"Cornellà-Castelldefels-Ratpenat-Begues", "fitxer":"cornella-castelldefels-ratpenat-begues.gpx", "desc":"BTT*Altres*cornella-castelldefels-ratpenat-begues.gpx*2023-11-24*67.2 Km*04:16:05*05:04:35*1647 m*1463 m*2m*480m*"},
			{"ruta":"Vilafranca del Penedès-Martorell", "fitxer":"vilafranca_del_penedes-martorell.gpx", "desc":"BTT*Altres*vilafranca_del_penedes-martorell.gpx*2022-10-21*48.4 Km*03:38:00*04:15:36*1414 m*1158 m*54m*635m*"},
			{"ruta":"Sant Vicenç de Calders-El Catllar-Bonastre", "fitxer":"st_vicenc_calders-el_catllar-bonastre.gpx", "desc":"BTT*Altres*st_vicenc_calders-el_catllar-bonastre.gpx*2024-01-26*73.0 Km*04:44:14*07:14:59*1568 m*1307 m*2m*289m*"},
			{"ruta":"Lleida-Aiguabarreig Segre i Cinca-La Granja d'Escarp", "fitxer":"lleida-aiguabarreig_segre_cinca-la_granja_descarp.gpx", "desc":"BTT*Altres*lleida-aiguabarreig_segre_cinca-la_granja_descarp.gpx*2023-09-15*94.3 Km*04:41:29*06:18:27*934 m*710 m*72m*301m*"},
			{"ruta":"Sabadell-Marquet de les Roques", "fitxer":"sabadell-marquet_de_les_roques.gpx", "desc":"BTT*Altres*sabadell-marquet_de_les_roques.gpx*2021-12-16*49.0 Km*03:20:08*03:54:08*1571 m*1107 m*162m*617m*"},
			{"ruta":"Olot-Girona", "fitxer":"olot-girona.gpx", "desc":"BTT*Altres*olot-girona.gpx*2022-12-10*59.1 Km*04:31:52*06:11:56*832 m*540 m*67m*598m*"},
			{"ruta":"Manresa-Vic", "fitxer":"manresa-vic.gpx", "desc":"BTT*Altres*manresa-vic.gpx*2022-10-28*64.0 Km*04:05:40*04:47:40*1857 m*1428 m*201m*936m*"},
			{"ruta":"Cabrerès 2023", "fitxer":"cabreres_2023.gpx", "desc":"BTT*Altres*cabreres_2023.gpx*2023-05-14*58.7 Km*05:26:10*05:50:24*2613 m*1969 m*441m*1142m*"},
			{"ruta":"Ripoll-Olot", "fitxer":"ripoll-olot.gpx", "desc":"BTT*Altres*ripoll-olot.gpx*2022-12-08*42.6 Km*03:54:52*06:26:50*1194 m*874 m*419m*1075m*"},
			{"ruta":"Maçanet_Massanes-Vic", "fitxer":"macanet_massanes-vic.gpx", "desc":"BTT*Altres*macanet_massanes-vic.gpx*2023-02-10*65.6 Km*05:28:56*06:09:40*2607 m*2064 m*60m*1088m*"},
			{"ruta":"Aiguafreda-Manresa", "fitxer":"aiguafreda-manresa.gpx", "desc":"BTT*Altres*aiguafreda-manresa.gpx*2023-05-26*71.8 Km*04:25:06*06:22:59*2389 m*1833 m*208m*928m*"},
			{"ruta":"Marxa dels Castells 2010", "fitxer":"marxa_castells.gpx", "desc":"senderisme*Altres*marxa_castells.gpx*2010-03-07*54.3 Km*08:53:33 *09:28:18*997 m*842 m*387m*576m*v36ArVA92Zq"},
			{"ruta":"Palautordera-Aiguafreda", "fitxer":"palautordera-aiguafreda.gpx", "desc":"BTT*Altres*palautordera-aiguafreda.gpx*2024-06-07*43.1 Km*02:39:01*03:21:39*2022 m*1738 m*190m*1266m*"},
			{"ruta":"Aiguafreda-Sabadell", "fitxer":"aiguafreda-sabadell.gpx", "desc":"BTT*Altres*aiguafreda-sabadell.gpx*2022-12-02*50.7 Km*02:32:52*03:42:16*1083 m*1000 m*521m*1048m*"},
			{"ruta":"Ripoll-Maçanet_Massanes (Via Verda)", "fitxer":"ripoll-macanet_massanes_via_verda.gpx", "desc":"BTT*Altres*ripoll-macanet_massanes_via_verda.gpx*2023-02-03*98.5 Km*04:38:16*05:22:56*1873 m*1207 m*60m*1129m*"},
			{"ruta":"Manresa-Sant Sadurní d'Anoia", "fitxer":"manresa-sant_sadurni_d_anoia.gpx", "desc":"BTT*Altres*manresa-sant_sadurni_d_anoia.gpx*2023-12-01*65.4 Km*04:43:03*05:09:52*1896 m*1696 m*142m*680m*"},
			{"ruta":"Sant Privat d'en Bas-Puisacalm-Sallent", "fitxer":"sant_privat_den_bas-puisacalm-sallent.gpx", "desc":"senderisme*Altres*sant_privat_den_bas-puisacalm-sallent.gpx*2022-12-09*11.7 Km*05:11:26*06:44:24*1299 m*1198 m*618m*1485m*"},
			{"ruta":"Alp-Collada de Toses-Planoles-Collet de les Barraques-Ribes de Freser", "fitxer":"alp-collada_toses-planoles-collet_barraques-ribes_freser.gpx", "desc":"ciclisme*Altres*alp-collada_toses-planoles-collet_barraques-ribes_freser.gpx*2024-03-15*64.5 Km*03:40:18*04:15:23*3072 m*2503 m*918m*1896m*"},
			{"ruta":"Beinn à Chrulaiste", "fitxer":"beinn-a-chrulaiste.gpx", "desc":"senderisme*Altres*beinn-a-chrulaiste.gpx*2022-08-10*10.8 Km*00:07:30*00:07:30*675 m*582 m*240m*855m*"},
			{"ruta":"Castells del Loira", "fitxer":"castells_loira.gpx", "desc":"ciclisme*Altres*castells_loira.gpx*2021-08-08*195.4 Km*11:28:03*23:58:28*2244 m*996 m*26m*111m*"},
			{"ruta":"Revilla-Refugio de Foratarruego-Miradores de Revilla", "fitxer":"revilla-refugio_foratarruego-miradores_de_revilla.gpx", "desc":"senderisme*Altres*revilla-refugio_foratarruego-miradores_de_revilla.gpx*2022-07-26*13.3 Km*00:00:00*04:50:49*824 m*704 m*1192m*1968m*"},
			{"ruta":"Aiguafreda-Monistrol de Calders", "fitxer":"aiguafreda-monistrol_de_calders.gpx", "desc":"BTT*Altres*aiguafreda-monistrol_de_calders.gpx*2023-05-12*46.9 Km*04:02:08*05:19:20*1888 m*1459 m*412m*926m*"},
			{"ruta":"Carros de Foc", "fitxer":"carros_foc.gpx", "desc":"senderisme*Altres*carros_foc.gpx*2003-09-18*67.1 Km*00:00:00*12:04:00*5577 m*4810 m*1880m*2747m*"},
			{"ruta":"All-Gréixer-Olopte", "fitxer":"all-greixer-olopte.gpx", "desc":"senderisme*Altres*all-greixer-olopte.gpx*2022-08-26*7.9 Km*00:00:12*02:18:53*309 m*222 m*1078m*1327m*"},
			{"ruta":"La Floresta-Vilanova i la Geltrú", "fitxer":"la_floresta-vilanova_i_la_geltru.gpx", "desc":"BTT*Altres*la_floresta-vilanova_i_la_geltru.gpx*2021-11-28*76.4 Km*05:46:32*06:06:08*2233 m*1750 m*11m*574m*"},
			{"ruta":"Castelldefels-Sitges", "fitxer":"castelldefels-sitges.gpx", "desc":"BTT*Altres*castelldefels-sitges.gpx*2022-02-24*47.7 Km*03:12:04*03:45:48*1418 m*1096 m*8m*461m*"},
			{"ruta":"Puigmal des del Collet de les Barraques", "fitxer":"puigmal-des-del-collet-de-les-barraques.gpx", "desc":"senderisme*Altres*puigmal-des-del-collet-de-les-barraques.gpx*2023-12-30*18.7 Km*06:02:48*07:27:27*1301 m*1224 m*1856m*2910m*"},
			{"ruta":"TODO-Urús-Penyes Altes-Refugi del Cortal de Vidal", "fitxer":"TODO-urus-penyes_altes-refugi_del_cortal_de_vidal.gpx", "desc":"senderisme*Altres*TODO-urus-penyes_altes-refugi_del_cortal_de_vidal.gpx*2022-08-26*17.0 Km*00:00:11*06:26:36*1200 m*1031 m*1255m*2258m*"},
			{"ruta":"Girona-Gavarres-Calonge-Maçanet", "fitxer":"girona-gavarres-calonge-macanet.gpx", "desc":"ciclisme*Altres*girona-gavarres-calonge-macanet.gpx*2023-12-22*92.7 Km*04:25:05*06:54:06*2086 m*1686 m*17m*475m*"},
			{"ruta":"Vilanova-Experience Garraf Penedès 2023", "fitxer":"vilanova-experience_garraf_penedes_2023.gpx", "desc":"BTT*Altres*vilanova-experience_garraf_penedes_2023.gpx*2023-01-13*64.3 Km*03:42:48*04:34:23*1858 m*1358 m*11m*329m*"},
			{"ruta":"Roncesvalles", "fitxer":"roncesvalles.gpx", "desc":"BTT*Altres*roncesvalles.gpx*1995-07-07*13.3 Km*00:19:30*00:19:30*395 m*257 m*887m*1188m*"},
			{"ruta":"Igualada-Calaf-Manresa-Igualada", "fitxer":"igualada-calaf-manresa-igualada.gpx", "desc":"BTT*Altres*igualada-calaf-manresa-igualada.gpx*2021-12-04*95.0 Km*05:42:36*06:52:36*2147 m*1689 m*237m*724m*"},
			{"ruta":"Vilafranca-Santa Coloma de Queralt-Igualada", "fitxer":"vilafranca-sta_coloma_queralt-igualada.gpx", "desc":"ciclisme*Altres*vilafranca-sta_coloma_queralt-igualada.gpx*2024-02-16*74.8 Km*03:39:30*04:50:09*1637 m*1461 m*193m*822m*"},
			{"ruta":"Espierba-Punta el Cuezo", "fitxer":"espierba-punta_el_cuezo.gpx", "desc":"senderisme*Altres*espierba-punta_el_cuezo.gpx*2022-07-25*10.1 Km*00:00:31*03:29:33*724 m*484 m*1380m*2088m*"}
		]},
		{"zona":"TransPirenaica","id":"transpirenaica","rutes":[
			{"ruta":"Transpirinenca. Fiscal-Aurín", "fitxer":"TP_11_fiscal-aurin.gpx", "desc":"BTT*TransPirenaica*TP_11_fiscal-aurin.gpx*1996-07-06*50.3 Km*00:05:56*09:06:19*1295 m*1073 m*774m*1678m*"},
			{"ruta":"Transpirinenca. Bagà-Noves de Segre", "fitxer":"TP_05_baga-noves_de_segre.gpx", "desc":"BTT*TransPirenaica*TP_05_baga-noves_de_segre.gpx*1995-07-06*66.9 Km*17:16:10*23:52:26*1778 m*1576 m*594m*1914m*"},
			{"ruta":"Transpirinenca. Camprodon-Planoles", "fitxer":"TP_03_camprodon-planoles.gpx", "desc":"BTT*TransPirenaica*TP_03_camprodon-planoles.gpx*1995-05-01*57.9 Km*16:37:52*00:40:04*1847 m*1799 m*940m*2059m*"},
			{"ruta":"Transpirinenca. Noves de Segre-Llavorsí", "fitxer":"TP_06_noves_de_segre-llavorsi.gpx", "desc":"BTT*TransPirenaica*TP_06_noves_de_segre-llavorsi.gpx*1995-07-06*51.9 Km*15:57:52*23:15:35*1407 m*1245 m*652m*1757m*"},
			{"ruta":"Transpirinenca. Etxalar-Hondarribia", "fitxer":"TP_16_etxalar-hondarribia.gpx", "desc":"BTT*TransPirenaica*TP_16_etxalar-hondarribia.gpx*1996-07-06*43.6 Km*00:08:07*07:37:09*1098 m*886 m*5m*450m*"},
			{"ruta":"Transpirinenca. Planoles-Bagà", "fitxer":"TP_04_planoles-baga.gpx", "desc":"BTT*TransPirenaica*TP_04_planoles-baga.gpx*1995-07-06*54.6 Km*00:17:04*07:17:24*1684 m*1491 m*821m*2104m*"},
			{"ruta":"Transpirinenca. Llançà-Albanyà", "fitxer":"TP_01_llanca-albanya.gpx", "desc":"BTT*TransPirenaica*TP_01_llanca-albanya.gpx*1995-05-01*75.5 Km*06:27:24*16:36:42*1667 m*1288 m*0m*362m*"},
			{"ruta":"Transpirinenca. Izaba-Roncesvalles", "fitxer":"TP_14_izaba-roncesvalles.gpx", "desc":"BTT*TransPirenaica*TP_14_izaba-roncesvalles.gpx*1996-07-06*73.3 Km*00:20:02*09:28:59*1766 m*1465 m*756m*1341m*"},
			{"ruta":"Transpirinenca. Echo-Izaba", "fitxer":"TP_13_echo-izaba.gpx", "desc":"BTT*TransPirenaica*TP_13_echo-izaba.gpx*1996-07-06*37.4 Km*00:03:34*06:54:15*1040 m*860 m*793m*1283m*"},
			{"ruta":"Transpirinenca. Espui-Bonansa", "fitxer":"TP_08_espui-bonansa.gpx", "desc":"BTT*TransPirenaica*TP_08_espui-bonansa.gpx*1995-07-06*61.9 Km*00:25:12*11:14:13*1891 m*1649 m*861m*1595m*"},
			{"ruta":"Transpirinenca. Bonansa-Escalona", "fitxer":"TP_09_bonansa-escalona.gpx", "desc":"BTT*TransPirenaica*TP_09_bonansa-escalona.gpx*1995-07-06*75.1 Km*12:25:18*23:59:13*2113 m*1706 m*622m*1615m*"},
			{"ruta":"Transpirinenca. Llavorsí-Espui", "fitxer":"TP_07_llavorsi-espui.gpx", "desc":"BTT*TransPirenaica*TP_07_llavorsi-espui.gpx*1995-07-06*58.1 Km*14:44:55*00:16:50*1872 m*1653 m*822m*2270m*"},
			{"ruta":"Transpirinenca. Albanyà-Camprodon", "fitxer":"TP_02_albanya-camprodon.gpx", "desc":"BTT*TransPirenaica*TP_02_albanya-camprodon.gpx*1995-05-01*61.7 Km*00:16:32*08:32:53*1995 m*1698 m*219m*986m*"},
			{"ruta":"Transpirinenca. Aurín-Echo", "fitxer":"TP_12_aurín-echo.gpx", "desc":"BTT*TransPirenaica*TP_12_aurín-echo.gpx*1996-07-06*71.5 Km*14:39:07*23:49:51*1693 m*1438 m*747m*1501m*"},
			{"ruta":"Transpirinenca. Roncesvalles-Etxalar", "fitxer":"TP_15_roncesvalles-etxalar.gpx", "desc":"BTT*TransPirenaica*TP_15_roncesvalles-etxalar.gpx*1996-07-06*66.6 Km*15:02:15*23:46:28*1676 m*1436 m*117m*1189m*"},
			{"ruta":"Transpirinenca. Escalona-Fiscal", "fitxer":"TP_10_escalona-fiscal.gpx", "desc":"BTT*TransPirenaica*TP_10_escalona-fiscal.gpx*1995-07-06*69.3 Km*13:45:21*00:20:37*2121 m*1805 m*621m*2034m*"}
		]},
		{"zona":"Port de la Selva","id":"port_de_la_selva","rutes":[
			{"ruta":"Port de la Selva-Selva Mar-Coll Bombo-Pla Causa-Puig Sardina-Roses-Mas Romanyac-GR92", "fitxer":"port_selva-coll_bombo-pla_causa-puig_sardina-roses-mas_romanyac-GR9.gpx", "desc":"BTT*Port de la Selva*port_selva-coll_bombo-pla_causa-puig_sardina-roses-mas_romanyac-GR9.gpx*2020-08-22*38.9 Km*02:49:34 *04:04:04*1693 m*1231 m*50m*488m*vMq5Ger5kQO"},
			{"ruta":"Port de la Selva-St Baldiri-Cala_Taballera-Cadaqués-GR92", "fitxer":"port_selva-st_baldiri-cala_taballera-cadaques-GR92.gpx", "desc":"BTT*Port de la Selva*port_selva-st_baldiri-cala_taballera-cadaques-GR92.gpx*2020-08-24*32.3 Km*03:25:28 *04:46:52*1163 m*984 m*0m*224m*vevYDQZp4yq"},
			{"ruta":"Port de la Selva-Cadaqués-GR-92", "fitxer":"port_de_la_selva-cadaques-gr-92.gpx", "desc":"senderisme*Port de la Selva*port_de_la_selva-cadaques-gr-92.gpx*2023-04-04*12.4 Km*02:51:56*03:07:08*610 m*517 m*8m*310m*"},
			{"ruta":"Banyuls-Rumpissar-Portbou", "fitxer":"banyuls-rumpissar-portbou.gpx", "desc":"senderisme*Port de la Selva*banyuls-rumpissar-portbou.gpx*2023-04-02*13.0 Km*03:58:40*04:28:36*854 m*743 m*2m*549m*"},
			{"ruta":"Port Selva-St Pere de Rodes-Llançà-Puig Tifeu-Garbet", "fitxer":"port_selva-st_pere_de_rodes-llanca-puig_tifeu-garbet.gpx", "desc":"BTT*Port de la Selva*port_selva-st_pere_de_rodes-llanca-puig_tifeu-garbet.gpx*2020-08-20*41.6 Km*03:12:44 *04:01:24*1373 m*1128 m*1m*522m*vrqD4PZL3wq"},
			{"ruta":"Port Selva-St Pere de Rodes-Llançà-Puig Tifell-Garbet", "fitxer":"port_selva-st_pere_de_rodes-llanca-puig_tifell-garbet.gpx", "desc":"BTT*Port de la Selva*port_selva-st_pere_de_rodes-llanca-puig_tifell-garbet.gpx*2020-08-20*41.6 Km*03:12:44 *04:01:24*1373 m*1128 m*1m*522m*vrqD4PZL3wq"},
			{"ruta":"Portbou-Colera-Llançà-Port de la Selva", "fitxer":"portbou-colera-llanca-port_de_la_selva.gpx", "desc":"senderisme*Port de la Selva*portbou-colera-llanca-port_de_la_selva.gpx*2023-04-03*17.6 Km*04:50:47*05:10:43*727 m*624 m*0m*234m*"},
			{"ruta":"ToDo-Camprodon - Port de la Selva /10-09-11", "fitxer":"todo-camprodon-port-de-la-selva-10-09-11.gpx", "desc":"BTT*Port de la Selva*camprodon-port-de-la-selva-10-09-11.gpx*2011-09-10*126.7 Km*04:55:26 *06:17:45*3374 m*2596 m*1m*1514m*vdvmB4n28NO"},
			{"ruta":"Port de la Selva-Sant Pere de Rodes-Sant Onofre-Palau-Saverdera", "fitxer":"port_de_la_selva-sant_pere_de_r-sant_onofre-palau_saverdera.gpx", "desc":"BTT*Port de la Selva*port_de_la_selva-sant_pere_de_r-sant_onofre-palau_saverdera.gpx*2021-08-01*32.5 Km*02:20:07*02:20:07*960 m*842 m*2m*506m*"},
			{"ruta":"Port de la Selva-Sant Pere de Rodes-Puig d'Esquers-Colera", "fitxer":"port_de_la_selva-sant_pere_de_rodes-puig_desquers-colera.gpx", "desc":"BTT*Port de la Selva*port_de_la_selva-sant_pere_de_rodes-puig_desquers-colera.gpx*2023-08-26*38.4 Km*02:54:47*03:28:10*1940 m*1718 m*0m*588m*"},
			{"ruta":"Port de la Selva-Selva de Mar-Mas Romanyac-Coll de Perafita-Cadaqués-GR92", "fitxer":"port_selva-selva_mar-mas_romanyac-perafita-cadaques-GR92.gpx", "desc":"BTT*Port de la Selva*port_selva-selva_mar-mas_romanyac-perafita-cadaques-GR92.gpx*2020-08-29*33.6 Km*02:28:00 *02:55:44*1002 m*822 m*1m*349m*v26M25p4YEq"},
			{"ruta":"Port de la Selva-Cala Taballera-Mas de Birba-GR92", "fitxer":"port_selva-taballera-birba-gr92.gpx", "desc":"BTT*Port de la Selva*port_selva-taballera-birba-gr92.gpx*2020-08-18*26.8 Km*02:15:12 *02:31:56*842 m*646 m*0m*257m*vRO7RoDdAyv"},
			{"ruta":"Port de la Selva-3 dòlmens de Taballera", "fitxer":"port_selva-3_dolmens_taballera.gpx", "desc":"BTT*Port de la Selva*port_selva-3_dolmens_taballera.gpx*2020-08-28*21.0 Km*01:45:52 *02:18:40*651 m*529 m*1m*316m*vYvrNeXGpLv"},
			{"ruta":"Port de la Selva-Vall de la Santa Creu", "fitxer":"port_selva-vall_sta_creu.gpx", "desc":"BTT*Port de la Selva*port_selva-vall_sta_creu.gpx*2020-08-26*10.7 Km*00:44:04 *00:48:56*273 m*203 m*2m*177m*vmqXowZjeL6"},
			{"ruta":"Port de la Selva-carretera de Roses a Coll de Perafita", "fitxer":"port_selva-carretera_roses_perafita.gpx", "desc":"BTT*Port de la Selva*port_selva-carretera_roses_perafita.gpx*2020-08-27*16.4 Km*01:38:08 *02:24:48*473 m*404 m*5m*334m*v36Ae2zyzG6"},
			{"ruta":"Port de la Selva-Perafita-Jóncols-Cadaqués", "fitxer":"port_de_la_selva-perafita-joncols-cadaques.gpx", "desc":"BTT*Port de la Selva*port_de_la_selva-perafita-joncols-cadaques.gpx*2021-08-04*41.8 Km*04:03:24*04:48:20*1341 m*1159 m*2m*421m*"},
			{"ruta":"Port de la Selva-Roses-dòlmens-Perafita", "fitxer":"port_de_la_selva-roses-dolmens-perafita.gpx", "desc":"BTT*Port de la Selva*port_de_la_selva-roses-dolmens-perafita.gpx*2021-08-02*34.2 Km*02:45:23*02:48:55*1002 m*895 m*5m*447m*"}
		]},
		{"zona":"Barcelona","id":"barcelona","rutes":[
			{"ruta":"Serra de Galliners v1", "fitxer":"serra_galliners-v1.gpx", "desc":"BTT*Barcelona*serra_galliners-v1.gpx*2024-04-05*56.2 Km*05:21:09*07:06:55*1931 m*1778 m*152m*308m*"},
			{"ruta":"Maria 16 anys", "fitxer":"maria_16_anys.gpx", "desc":"ciclisme*Barcelona*maria_16_anys.gpx*2021-11-14*24.9 Km*01:48:40*01:52:08*375 m*292 m*16m*76m*"},
			{"ruta":"Manresa-Camí Ral-Barcelona", "fitxer":"manresa-cami_ral-barcelona.gpx", "desc":"BTT*Barcelona*manresa-cami_ral-barcelona.gpx*2023-04-14*71.9 Km*05:06:04*06:04:08*2384 m*1759 m*75m*758m*"},
			{"ruta":"Volta per Collserola 7", "fitxer":"volta_per_collserola_7.gpx", "desc":"BTT*Barcelona*volta_per_collserola_7.gpx*2022-04-08*29.0 Km*02:12:52*02:17:28*1251 m*906 m*69m*482m*"},
			{"ruta":"Terrassa-Barcelona", "fitxer":"terrassa-barcelona.gpx", "desc":"BTT*Barcelona*terrassa-barcelona.gpx*2024-06-14*31.6 Km*01:54:15*02:24:11*859 m*755 m*73m*435m*"},
			{"ruta":"Volta per Collserola 3", "fitxer":"volta_per_collserola_3.gpx", "desc":"BTT*Barcelona*volta_per_collserola_3.gpx*2021-02-11*28.4 Km*02:00:24*02:02:12*931 m*699 m*62m*396m*"},
			{"ruta":"Entreno Collserola Oriental v2", "fitxer":"entreno_collserola_oriental_v2.gpx", "desc":"BTT*Barcelona*entreno_collserola_oriental_v2.gpx*2012-12-13*40.3 Km*02:27:03 *03:58:28*1918 m*1422 m*74m*352m*vXOd3mjr54v"},
			{"ruta":"Integral Carretera de les Aigües 2", "fitxer":"integral_carretera_aigues_2.gpx", "desc":"BTT*Barcelona*integral_carretera_aigues_2.gpx*2020-09-13*25.8 Km*01:27:28 *01:40:40*725 m*476 m*64m*393m*"},
			{"ruta":"Baixador de Vallvidrera-Sitges", "fitxer":"baixador_vallvidrera-sitges.gpx", "desc":"BTT*Barcelona*granollers-baixador_vallvidrera-sitges.gpx*2021-01-31*53.2 Km*03:37:48*03:40:28*1585 m*1235 m*9m*464m*"},
			{"ruta":"Volta per Collserola", "fitxer":"volta_per_collserola_1.gpx", "desc":"BTT*Barcelona*volta_per_collserola_1.gpx*2020-11-22*30.7 Km*02:08:12 *02:27:24*1033 m*798 m*53m*434m*"},
			{"ruta":"Volta per Collserola 10", "fitxer":"volta_per_collserola_10.gpx", "desc":"BTT*Barcelona*volta_per_collserola_10.gpx*2022-06-12*42.5 Km*03:11:00*03:13:44*1025 m*797 m*11m*433m*"},
			{"ruta":"Volta per Collserola 6", "fitxer":"volta_per_collserola_6.gpx", "desc":"BTT*Barcelona*volta_per_collserola_6.gpx*2021-06-19*65.1 Km*04:32:04*04:47:08*2015 m*1577 m*58m*433m*"},
			{"ruta":"Volta per Collserola 5-Single Track", "fitxer":"volta_per_collserola_5-single_track.gpx", "desc":"BTT*Barcelona*volta_per_collserola_5-single_track.gpx*2021-05-24*28.4 Km*02:21:48*02:26:20*1059 m*789 m*77m*405m*"},
			{"ruta":"Baixador de Vallvidrera-Papiol-La Floresta", "fitxer":"baixador_vallvidrera-papiol-la_floresta.gpx", "desc":"BTT*Barcelona*baixador_vallvidrera-papiol-la_floresta.gpx*2021-11-25*19.6 Km*01:34:48*01:36:40*894 m*660 m*72m*435m*"},
			{"ruta":"Santa Coloma de Gramenet-Les Maleses", "fitxer":"santa_coloma_gr-les_maleses.gpx", "desc":"BTT*Barcelona*santa_coloma_gr-les_maleses.gpx*2023-04-21*24.0 Km*01:59:44*02:33:44*1111 m*916 m*38m*455m*"},
			{"ruta":"Trialerada per Collserola", "fitxer":"trialerada-per-collserola.gpx", "desc":"BTT*Barcelona*trialerada-per-collserola.gpx*2013-02-07*22.0 Km*01:28:09 *01:56:59*812 m*623 m*129m*469m*v1vjpPwxeY6*3 trialeres de baixada bastant practicables (menys alguns passos de la primera)"},
			{"ruta":"Vallcarca-Tibidabo-PiXandri-Sol-i-aire", "fitxer":"vallcarca-tibidabo-pixandri-sol-i-aire.gpx", "desc":"BTT*Barcelona*vallcarca-tibidabo-pixandri-sol-i-aire.gpx*2014-01-12*32.2 Km*00:00:20 *08:19:41*1148 m*989 m*115m*492m*vQvxVWKJw96"},
			{"ruta":"Santa Coloma-El Masnou-Passeig Marítim", "fitxer":"sta_coloma-masnou-pg_maritim.gpx", "desc":"BTT*Barcelona*sta_coloma-masnou-pg_maritimgpx*2021-01-14*45.4 Km*03:02:40*03:26:00*1215 m*914 m*0m*429m*"},
			{"ruta":"Cornellà-Begues-Ratpenat-Castelldefels", "fitxer":"cornella-begues-ratpenat-castelldefels.gpx", "desc":"BTT*Barcelona*cornella-begues-ratpenat-castelldefels.gpx*2024-06-21*41.2 Km*02:44:26*02:58:11*1280 m*1090 m*2m*479m*"},
			{"ruta":"Baixador Vallvidrera-Turons Can Pasqual-St Feliu-Passeig Aigües", "fitxer":"Baixador_Vallvidrera-Turons_Can_Pasqual-St_Feliu-Passeig_Aigues.gpx", "desc":"BTT*Barcelona*Baixador_Vallvidrera-Turons_Can_Pasqual-St_Feliu-Passeig_Aigues.gpx*2020-12-27*28.7 Km*02:31:16*02:32:52*1510 m*1084 m*79m*463m*"},
			{"ruta":"Integral Carretera de les Aigües 1", "fitxer":"integral_carretera_aigues_1.gpx", "desc":"BTT*Barcelona*integral_carretera_aigues_1.gpx*2020-05-03*40.4 Km*23:44:58 *23:58:30*949 m*604 m*32m*434m*vRO7dx9QEK6"},
			{"ruta":"Montjuïc", "fitxer":"montjuic.gpx", "desc":"BTT*Barcelona*montjuic.gpx*2023-01-27*30.2 Km*02:20:44*02:37:52*926 m*702 m*16m*181m*"},
			{"ruta":"Terrassa-Ullastrell-Martorell-Cornellà", "fitxer":"terrassa-ullastrell-martorell-cornella.gpx", "desc":"BTT*Barcelona*terrassa-ullastrell-martorell-cornella.gpx*2022-01-09*49.9 Km*02:56:52*02:57:40*831 m*548 m*8m*392m*"},
			{"ruta":"Vallençana-Puig Castellar", "fitxer":"vallencana-puig_castellar.gpx", "desc":"senderisme*Barcelona*vallencana-puig_castellar.gpx*2022-01-23*5.4 Km*01:28:40*01:53:28*319 m*288 m*45m*278m*"},
			{"ruta":"Integral Carretera de les Aigües 3", "fitxer":"integral_carretera_aigues_3.gpx", "desc":"BTT*Barcelona*integral_carretera_aigues_3.gpx*2020-01-02*27.2 Km*01:46:17 *01:55:54*762 m*501 m*65m*392m*vPv4JZ38P36"},
			{"ruta":"Baixador Vallvidrera-Delta Llobregat", "fitxer":"baixador_vallvidrera_delta_llobregat.gpx", "desc":"BTT*Barcelona*baixador_vallvidrera_delta_llobregat.gpx*2020-09-17*41.0 Km*02:13:52 *03:46:00*631 m*329 m*-1m*348m*vevWNLyMPyq"},
			{"ruta":"ToDo-Volta per Collserola 5", "fitxer":"todo-volta_per_collserola_5.gpx", "desc":"BTT*Barcelona*todo-volta_per_collserola_5.gpx*2021-05-09*32.2 Km*00:00:20*08:19:41*1153 m*989 m*115m*492m*"},
			{"ruta":"Volta per Collserola 5 Floresta Sant Just", "fitxer":"volta_per_collserola_5_floresta_st_just.gpx", "desc":"BTT*Barcelona*volta_per_collserola_5_floresta_st_just.gpx*2021-06-09*23.2 Km*01:47:36*01:57:44*729 m*532 m*63m*423m*"},
			{"ruta":"La Floresta-Papiol-St Bartomeu-Olèrdola-Baixador Vallvidrera", "fitxer":"la_floresta-papiol-st_bartomeu-olerdola-baixador_vallvidrera.gpx", "desc":"BTT*Barcelona*la_floresta-papiol-st_bartomeu-olerdola-baixador_vallvidrera.gpx*2021-01-07*28.5 Km*02:10:36*02:12:48*1389 m*1023 m*72m*369m*"},
			{"ruta":"Volta per Collserola 4 corriols", "fitxer":"volta_per_collserola_4-corriols.gpx", "desc":"BTT*Barcelona*volta_per_collserola_4-corriols.gpx*2021-03-26*33.9 Km*03:24:59*03:46:41*1344 m*1014 m*61 m*347 m*"},
			{"ruta":"Caldes de Montbui-Barcelona", "fitxer":"caldes_de_montbui-barcelona.gpx", "desc":"BTT*Barcelona*caldes_de_montbui-barcelona.gpx*2022-10-09*29.9 Km*01:38:28*01:46:56*204 m*112 m*10m*240m*"},
			{"ruta":"Volta per Collserola-3 cims", "fitxer":"volta_per_collserola-3_cims.gpx", "desc":"BTT*Barcelona*volta_per_collserola-3_cims.gpx*2022-02-10*60.5 Km*04:22:32*05:28:34*2742 m*1924 m*69m*448m*"},
			{"ruta":"Cornellà-delta del Llobregat", "fitxer":"cornella-delta_del_llobregat.gpx", "desc":"BTT*Barcelona*cornella-delta_del_llobregat.gpx*2023-01-08*26.2 Km*01:46:04*02:08:28*267 m*184 m*4m*38m*"},
			{"ruta":"Volta per Collserola 14", "fitxer":"volta_per_collserola_14.gpx", "desc":"BTT*Barcelona*volta_per_collserola_14.gpx*2024-04-23*35.9 Km*02:32:13*02:41:21*1599 m*1390 m*68m*465m*"},
			{"ruta":"Poblat Ibèric Puig Castellar", "fitxer":"poblat-iberic-puig-castellar.gpx", "desc":"BTT*Barcelona*poblat-iberic-puig-castellar.gpx*2011-01-11*7.9 Km*00:52:08 *00:52:08*339 m*304 m*44m*263m*v36ArVAXKZq"},
			{"ruta":"Corriols de la Vall de Sant Just", "fitxer":"corriolada_per_sant_just_desvern.gpx", "desc":"BTT*Barcelona*corriolada_per_sant_just_desvern.gpx*2013-04-11*19.0 Km*01:14:04 *01:41:35*1059 m*600 m*164m*350m*vLqeN9EYdRv"},
			{"ruta":"Sant Sadurní d'Anoia-Barcelona", "fitxer":"sant_sadurni_danoia-barcelona.gpx", "desc":"BTT*Barcelona*sant_sadurni_danoia-barcelona.gpx*2022-03-31*48.5 Km*03:06:28*03:45:32*1343 m*1025 m*20m*464m*"},
			{"ruta":"Volta per Collserola 7-BCN-St Cugat", "fitxer":"volta_per_collserola_7-bcn-st_cugat.gpx", "desc":"BTT*Barcelona*volta_per_collserola_7-bcn-st_cugat.gpx*2021-12-23*29.7 Km*01:59:32*02:02:06*1036 m*764 m*75m*433m*"},
			{"ruta":"Volta per Collserola 4-corriols v2", "fitxer":"volta_per_collserola_4-corriols-v2.gpx", "desc":"BTT*Barcelona*volta_per_collserola_4-corriols-v2.gpx*2021-05-16*27.8 Km*02:22:36*02:58:24*1025 m*774 m*61m*348m*"},
			{"ruta":"Montjuïc 360", "fitxer":"montjuïc_360.gpx", "desc":"BTT*Barcelona*montjuïc_360.gpx*2023-10-20*11.0 Km*00:56:26*01:17:59*457 m*407 m*15m*181m*"},
			{"ruta":"Volta per Collserola 6-Can Pascual-La Floresta-Les Planes", "fitxer":"volta_per_collserola_6-can_pascual-floresta-planes.gpx", "desc":"BTT*Barcelona*volta_per_collserola_6-can_pascual-floresta-planes.gpx*2021-06-07*38.7 Km*02:49:27*02:51:23*1361 m*1049 m*63m*506m*"},
			{"ruta":"Volta per Collserola 2", "fitxer":"volta_per_collserola_2.gpx", "desc":"BTT*Barcelona*volta_per_collserola_2.gpx*2020-12-13*37.9 Km*02:22:00 *02:49:12*1146 m*817 m*92m*434m*"},
			{"ruta":"Volta per Collserola 13", "fitxer":"volta_per_collserola_13.gpx", "desc":"BTT*Barcelona*volta_per_collserola_13.gpx*2024-03-08*19.6 Km*01:43:13*01:44:42*958 m*881 m*104m*369m*"},
			{"ruta":"Sitges-Barcelona", "fitxer":"sitges-barcelona.gpx", "desc":"BTT*Barcelona*sitges-barcelona.gpx*2021-07-20*52.0 Km*03:39:04*03:39:44*1435 m*1167 m*6m*464m*"},
			{"ruta":"Barcelona-Arrabassada-Sant Cugat-Cerdanyola-Barcelona", "fitxer":"barcelona-arrabassada-st_cugat-cerdanyola-barcelona.gpx", "desc":"ciclisme*Barcelona*barcelona-arrabassada-st_cugat-cerdanyola-barcelona.gpx*2023-05-21*38.0 Km*01:50:52*01:52:28*1119 m*818 m*54m*432m*"},
			{"ruta":"Volta per Collserola 12", "fitxer":"volta_per_collserola_12.gpx", "desc":"BTT*Barcelona*volta_per_collserola_12.gpx*2024-03-20*21.6 Km*01:34:43*01:41:44*987 m*886 m*74m*434m*"},
			{"ruta":"Cornellà-Martorell", "fitxer":"cornella-martorell.gpx", "desc":"BTT*Barcelona*cornella-martorell.gpx*2023-06-16*46.6 Km*02:40:34*03:53:08*519 m*378 m*8m*62m*"},
			{"ruta":"Volta per Collserola 8-Pujada de Can Borni", "fitxer":"volta_per_collserola_8-pujada_can_borni.gpx", "desc":"BTT*Barcelona*volta_per_collserola_8-pujada_can_borni.gpx*2022-05-13*22.4 Km*02:02:52*02:04:04*982 m*762 m*76m*432m*"},
			{"ruta":"Volta per Collserola 9", "fitxer":"volta_per_collserola_9.gpx", "desc":"BTT*Barcelona*volta_per_collserola_9.gpx*2022-05-31*33.1 Km*02:31:00*03:20:12*1240 m*980 m*34m*492m*"},
			{"ruta":"Mercabarna-Delta del Llobregat", "fitxer":"mercabarna-delta_llobregat.gpx", "desc":"BTT*Barcelona*mercabarna-delta_llobregat.gpx*2020-12-30*19.3 Km*01:48:24*01:52:04*121 m*93 m*0m*10m*"},
			{"ruta":"Serra de Galliners-Part 1", "fitxer":"serra_galliners_part_1.gpx", "desc":"BTT*Barcelona*serra_galliners_part_1.gpx*2024-05-03*34.5 Km*03:01:15*03:26:00*1224 m*1080 m*169m*308m*"},
			{"ruta":"Carretera de la Roca", "fitxer":"carretera_de_la_roca.gpx", "desc":"ciclisme*Barcelona*carretera_de_la_roca.gpx*2022-04-28*46.3 Km*02:08:08*02:08:24*563 m*332 m*0m*179m*"},
			{"ruta":"Gelida-Sant Vicenç dels Horts", "fitxer":"gelida-sant_vicenc_dels_horts.gpx", "desc":"BTT*Barcelona*gelida-sant_vicenc_dels_horts.gpx*2021-10-16*33.4 Km*02:33:04*02:55:40*1092 m*867 m*28m*499m"},
			{"ruta":"Granollers-Sta Coloma de Gr", "fitxer":"granollers-sta_coloma_gr.gpx", "desc":"BTT*Barcelona*granollers-sta_coloma_gr.gpx*2021-02-16*46.0 Km*03:13:32*03:21:04*1326 m*1045 m*12m*485m*"},
			{"ruta":"Volta per Collserola 11", "fitxer":"volta_per_collserola_11.gpx", "desc":"BTT*Barcelona*volta_per_collserola_11.gpx*2022-09-02*27.3 Km*02:21:36*02:35:32*1268 m*902 m*74m*433m*"},
			{"ruta":"Gallecs-Palau-Solità-Polinyà-Santiga-riu Ripoll", "fitxer":"gallecs-palau-solita-polinya-santiga-ripoll.gpx", "desc":"BTT*Barcelona*gallecs-palau-solita-polinya-santiga-ripoll.gpx*2022-01-29*57.0 Km*03:19:04*03:30:16*978 m*751 m*18m*246m*"},
			{"ruta":"Volta per Collserola-4 ermites", "fitxer":"volta_collserola_4_ermites.gpx", "desc":"BTT*Barcelona*volta_collserola_4_ermites.gpx*2021-03-12*47.0 Km*03:21:52*03:29:36*1338 m*1027 m*25m*430m*"},
			{"ruta":"Serra de Galliners-Part 2", "fitxer":"serra_galliners_part_2.gpx", "desc":"BTT*Barcelona*serra_galliners_part_2.gpx*2024-05-24*38.9 Km*03:08:16*03:34:53*1267 m*1155 m*150m*297m*"}
		]},
		{"zona":"Montseny","id":"montseny","rutes":[
			{"ruta":"Collformic-Matagalls", "fitxer":"collformic-matagalls.gpx", "desc":"senderisme*Montseny*collformic-matagalls.gpx*2016-05-01*7.1 Km*01:12:50*01:49:54*570 m*544 m*1143m*1674m*"},
			{"ruta":"Dòlmens de la Serra de l'Arca-Pla de la Calma-Aiguafreda", "fitxer":"dolmens_serra_de_larca-pla_calma-aiguafreda.gpx", "desc":"BTT*Montseny*dolmens_serra_de_larca-pla_calma-aiguafreda.gpx*2021-05-28*41.6 Km*00:40:15*01:30:27*2012 m*1431 m*397m*1211m*"},
			{"ruta":"Viladrau-Matagalls-Agudes-Turó de l'Home-Santa Fe", "fitxer":"viladrau-matagalls-agudes-turo_home-santa_fe.gpx", "desc":"senderisme*Montseny*viladrau-matagalls-agudes-turo_home-santa_fe.gpx*2003-09-18*24.7 Km*00:00:00*02:41:20*2175 m*1835 m*726m*1683m*"},
			{"ruta":"Montseny-Turó de l'Home", "fitxer":"montseny-turo_home.gpx", "desc":"senderisme*Montseny*montseny-turo_home.gpx*2004-01-23*5.4 Km*01:28:40*01:53:28*319 m*288 m*45m*278m*"},
			{"ruta":"Coll de Sant Marçal-Matagalls", "fitxer":"coll_sant_marcal-matagalls.gpx", "desc":"senderisme*Montseny*coll_sant_marcal-matagalls.gpx*2022-06-04*8.3 Km*02:54:55*04:02:08*719 m*665 m*1104m*1694m*"},
			{"ruta":"La Garriga-Vallcàrquera-Pla de la Calma-El Brull-Aiguafreda", "fitxer":"la_garriga-vallcarquera-pla_de_la_calma-el_brull-aiguafreda.gpx", "desc":"BTT*Montseny*la_garriga-vallcarquera-pla_de_la_calma-el_brull-aiguafreda.gpx*2022-01-14*55.2 Km*04:33:43*04:55:11*2422 m*1745 m*246m*1266m*"},
			{"ruta":"Canovelles-Samalus-Roc Centelles-Cànoves-Marata", "fitxer":"Canovelles-Samalus-Roc_Centelles-Canoves-Marata.gpx", "desc":"BTT*Montseny*Canovelles-Samalus-Roc_Centelles-Canoves-Marata.gpx*2021-04-16*41.3 Km*03:31:41*03:37:09*1245 m*1029 m*151m*996*"},
			{"ruta":"La Garriga-Vallforners-Pla de la Calma-La Móra-Aiguafreda", "fitxer":"la_garriga-vallforners-pla_calma-la_mora-aiguafreda.gpx", "desc":"BTT*Montseny*la_garriga-vallforners-pla_calma-la_mora-aiguafreda.gpx*2021-06-04*37.3 Km*02:57:28*02:58:12*1431 m*1193 m*257m*1293m*"},
			{"ruta":"Aiguafreda-Fondrats-El Bellit-Tagamanent", "fitxer":"aiguafreda-fondrats-bellit-tagamanent.gpx", "desc":"senderisme*Montseny*aiguafreda-fondrats-bellit-tagamanent.gpx*2019-12-23*14.1 Km*00:00:57*03:30:46*887 m*707 m*408m*1100m*"},
			{"ruta":"Sant Celoni-Turó de l'Home", "fitxer":"sant_celoni-turo_de_lhome.gpx", "desc":"BTT*Montseny*sant_celoni-turo_de_lhome.gpx*2021-06-12*51.1 Km*04:24:28*05:09:48*1942 m*1714 m*129m*1666m*748833287"},
			{"ruta":"Seva-Matagalls-Viladrau", "fitxer":"seva-matagalls-viladrau.gpx", "desc":"BTT*Montseny*seva-matagalls-viladrau.gpx*2022-05-19*42.9 Km*04:18:40*05:22:49*1781 m*1483 m*636m*1694m*"},
			{"ruta":"Vic-Collformic-Canovelles", "fitxer":"vic-collformic-canovelles.gpx", "desc":"BTT*Montseny*vic-collformic-canovelles.gpx*2022-05-05*62.8 Km*03:53:56*04:25:08*1837 m*1413 m*146m*1344m*"},
			{"ruta":"Granollers-Roc de Centelles", "fitxer":"granollers-roc_de_centelles.gpx", "desc":"BTT*Montseny*granollers-roc_de_centelles.gpx*2021-04-23*53.3 Km*04:02:34*04:04:42*1803 m*1426 m*133m*1017m*"},
			{"ruta":"Aiguafreda-Collformic-Pla de la Calma-Tagamanent", "fitxer":"Aiguafreda-Collformic-Pla_de_la_Calma-Tagamanent.gpx", "desc":"BTT*Montseny*Aiguafreda-Collformic-Pla_de_la_Calma-Tagamanent.gpx*2021-04-30*36.0 Km*03:48:28*04:00:41*1496 m*1227 m*395m*1345m*"},
			{"ruta":"Els Castellets-Les Agudes-Turó de l'Home", "fitxer":"els_castellets-les_agudes-turo_de_lhome.gpx", "desc":"senderisme*Montseny*els_castellets-les_agudes-turo_de_lhome.gpx*2021-12-29*13.8 Km*04:35:20*05:18:08*1068 m*956 m*1018m*1681m*"},
			{"ruta":"Llinars-El Sui-Pla de la Calma-El Bellit-Aiguafreda", "fitxer":"llinars-el_sui-pla_de_la_calma-el_bellit-aiguafreda.gpx", "desc":"BTT*Montseny*llinars-el_sui-pla_de_la_calma-el_bellit-aiguafreda.gpx*2022-02-03*33.2 Km*03:21:56*03:57:16*1593 m*1351 m*184m*1302m*"},
			{"ruta":"Aiguafreda-Vallcàrquera-Cànoves-La Garriga", "fitxer":"aiguafreda-vallcarquera-canoves-la_garriga.gpx", "desc":"BTT*Montseny*aiguafreda-vallcarquera-canoves-la_garriga.gpx*2022-01-21*43.2 Km*03:30:56*04:25:53*2094 m*1537 m*242m*898m*"},
			{"ruta":"Aiguafreda-Vallcàrquera-Cànoves-La Garriga", "fitxer":"aiguafreda-vallcarquera-canoves-la_garriga.gpx", "desc":"BTT*Montseny*aiguafreda-vallcarquera-canoves-la_garriga.gpx*2022-01-21*43.2 Km*03:30:56*04:25:53*2094 m*1537 m*242m*898m*"}
		]},
		{"zona":"Montañas Vacías","id":"montanas_vacias","rutes":[
			{"ruta":"MV2-Villahermosa del Río-Cabra de Mora", "fitxer":"MV2-villahermosa_del_rio-cabra_de_mora.gpx", "desc":"BTT*Montañas Vacías*MV2-villahermosa_del_rio-cabra_de_mora.gpx*2022-07-10*77.4 Km*08:29:31*11:30:41*2708 m*2327 m*874m*2016m*"},
			{"ruta":"MV8-Barracas-Sagunt", "fitxer":"MV8-barracas-sagunt.gpx", "desc":"BTT*Montañas Vacías*MV8-barracas-sagunt.gpx*2022-07-16*81.6 Km*04:38:24*05:57:00*1290 m*509 m*40m*987m*"},
			{"ruta":"MV7-Torrebaja-Javalambre-Puebla de Valverde-Barracas", "fitxer":"MV7-torrebaja-javalambre-puebla_de_valverde-barracas.gpx", "desc":"BTT*Montañas Vacías*MV7-torrebaja-javalambre-puebla_de_valverde-barracas.gpx*2022-07-15*108.1 Km*09:31:36*13:09:25*2924 m*2274 m*727m*2017m*"},
			{"ruta":"MV6-Zafrilla-Torrebaja", "fitxer":"MV6-zafrilla-torrebaja.gpx", "desc":"BTT*Montañas Vacías*MV6-zafrilla-torrebaja.gpx*2022-07-14*57.8 Km*04:35:16*05:33:08*1141 m*889 m*745m*1496m*"},
			{"ruta":"MV4-Bezas-Griegos", "fitxer":"MV4-bezas-griegos.gpx", "desc":"BTT*Montañas Vacías*MV4-bezas-griegos.gpx*2022-07-12*82.5 Km*08:15:11*11:20:02*2303 m*2081 m*1118m*1779m*"},
			{"ruta":"MV1-Castelló-Villahermosa del Río", "fitxer":"MV1-castello-villahermosa_del_rio.gpx", "desc":"BTT*Montañas Vacías*MV1-castello-villahermosa_del_rio.gpx*2022-07-09*61.1 Km*05:17:16*06:43:36*1965 m*1531 m*27m*988m*"},
			{"ruta":"MV3-Cabra de Mora-Bezas", "fitxer":"MV3-cabra_de_mora-bezas.gpx", "desc":"BTT*Montañas Vacías*MV3-cabra_de_mora-bezas.gpx*2022-07-11*75.1 Km*09:07:16*12:23:49*1829 m*1552 m*875m*1506m*"},
			{"ruta":"MV5-Griegos-Zafrilla", "fitxer":"MV5-griegos-zafrilla.gpx", "desc":"BTT*Montañas Vacías*MV5-griegos-zafrilla.gpx*2022-07-13*89.6 Km*06:11:08*12:05:10*1916 m*1526 m*1103m*1659m*"}
		]}
	];

	//Algorisme d'ordenació alfabètica
	var rutes_nou = [];
	for (var i=0;i<rutes.length;i++) {
		var obj = rutes[i];
		var obj_rutes = obj.rutes;
		obj_rutes.sort(function(a, b){
			//if(a.ruta < b.ruta) { return -1; }
			//if(a.ruta > b.ruta) { return 1; }
			//return 0;
			//bona comparació amb accents
			return a.ruta.localeCompare(b.ruta);
		});
		rutes_nou.push(obj);
	}

	rutes_nou.sort(function(a, b){
		//if(a.zona < b.zona) { return -1; }
		//if(a.zona > b.zona) { return 1; }
		//return 0;
		//bona comparació amb accents
		return a.zona.localeCompare(b.zona);
	});

	return rutes_nou;
}

export default llista_rutes;
